@import "../../../assets/scss/goals";

// Space goals styles
.space-goals-wrapper {
  .page-container {
    .overview-inner-section {
      .page-content {
        padding-top: 3.4rem;
        padding-bottom:0;
        @include bp(xl-max) {
          padding-bottom: 0;
        }

        .no-data-wrapper {
          margin-bottom: 2.4rem;
          margin-top: 2.4rem;
        }
        .page-filters-wrapper {
          .left-filter {
            .sort-by { 
              display: flex;
              align-items: center;
              margin: 0;
            }
          }
        }
      }
    }

    // .goals-container {
    //   .goals-left-col {}
    // }
  }
}
