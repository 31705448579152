@import "../scss/colors";
@import "../scss/mixins";
@import "../scss/extended-classes";
@import "../scss/break-points";

// Profile tooltip
.profile-tooltip-wrap {
  padding: 2.4rem 2.4rem 1.6rem;
  min-width: 28rem;
  max-width: 38rem;
  left: 0;
  bottom: 100%;
  border: 1px solid #D9C4FF;
  border-radius: 0.8rem;
  background-color: $white;
  box-shadow: 0px 12px 30px 0px #651FFF1A;
  transition: all ease-in 0.2s;
  z-index: 1;
  max-height: 26.8rem;
  overflow-y: auto;
  @include custom-scroll;
  
  .user-detail-head {
    display: flex;
    align-items: center;    
    margin-bottom: 1.2rem;
    .profile-pic {
      width: 5.6rem;
      min-width: 5.6rem;
      height: 5.6rem;
      margin-right: 0.8rem;
      border-radius: 100%;
      img, .initials {
        width: inherit !important;  
        border-radius: inherit !important;
        min-width: inherit !important;
        height: inherit !important;
        font-size: 2.8rem !important;
        line-height: 1.3;
        font-weight: 500;
        text-transform: uppercase;
        background: $lightPurple;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    .user-description {
      h4 {
        color: $black1;
        margin: 0 0 0.4rem;
        font-size: 1.8rem;
        @include line-height(18,24);
      }
      .profile {
        color: $black1;
        font-size: 1.2rem;
        @include line-height(12,16);
      }
    }
  }
  .content-wrap {
    //border-bottom: 1px solid $grey2;
    .content-row {
      color: $black1;
      font-size: 1.2rem;
      padding: 0 0 0.8rem 0;
      @include line-height(12,16);   
      display: flex;   
      align-items: center;
      border: 0;
      .icon, img {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.8rem;
      }
      &.email {
        word-break: break-all;
      }
      &.tag-wrap {
        padding-bottom: 0;
        .MuiStack-root {
          flex-wrap: wrap;
          .MuiChip-root {
            margin: 0 0.8rem 0.8rem 0;
            background-color: $lightPurple;
            color: $black1;
            font-size: 1rem;
            @include line-height(10,16);
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 1.2rem;
            padding: 0.2rem 0.8rem;
            height: 2rem;
            cursor: default;
            .MuiChip-label {
              padding: 0;
            }
          }
        }
      }
    }
    &:last-child {
      padding-top: 0.8rem;
      border: none;      
    }
  }
}

.user-profile-tooltip-popover {
  .MuiPaper-root {
    border-radius: 0.8rem !important;
  }
}