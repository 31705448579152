@import "../../../assets/scss/colors";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/extended-classes";
@import "../../../assets/scss/break-points";

// Playground Welcome page 
.playground-welcome-page {
  .inner-content {
    .close-icon{
      @include bp(sm-max) {
      position: absolute;
      right: 0;
      }
    }
    .welcome-content {
      height: 100vh;
      display: flex;
      align-items: center;
      .container.xs {
        max-width: 64.8rem;
      }
      .container {
        .flex-wrapper {
          flex-wrap: nowrap;
          align-items: center;
          position: relative;
          justify-content: center;
          .welcome-logo {            
            position: absolute;
            bottom: -7.8rem;
            left: -9.2rem;
            margin: 0;
            z-index: -1;            
            @include bp(lg-min) {
              width: 17rem;
              height: 17rem;
            }
            @include bp(lg-max) {
              width: 13rem;
              //margin-left: -12.8rem;
            }
            @include bp(sm-max) {
              left: 0;
            }
            img { 
              width: 17rem;
              height: auto;
            }
          }
          .option-selection {
            .title {
              font-size: 2.8rem;
              font-weight: 500;
              color: $black1;
              @include line-height(28, 36);
              text-align: center;
              margin: 0 0 3.2rem 0;
              @include bp(sm-max) {
                font-size: 2.2rem;
              }
            }
            .link-button-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              @include bp(sm-max) {
                flex-wrap: wrap;
              }
              
              .link-button {
                margin: 0 0.8rem 0.8rem 0;
                width: 15.2rem;
                height: 14.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                border: 2px solid transparent;
                border-radius: .8rem;
                box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.05), 0px 2.0rem 3.2rem 0 rgba(0, 0, 0, 0.08);
                @include bp(smd-max) {
                  width: 12.2rem;
                  height: 10.8rem;
                }                
                &.selected,
                &:hover {
                  border: 2px solid $purple4;
                }
                &.static {
                  &.selected,
                  &:hover {
                    border: 2px solid transparent;
                  }
                }
                img, .be-upload-document { 
                  width: 3.2rem;
                  height: auto;
                  color: $black1;
                  font-size: 3.2rem;
                }
                .title {
                  margin: 0;
                  color: $black1;
                  font-size: 1.2rem;
                  font-weight: 500;
                  @include line-height(12, 16);
                }
              }
            }
          }
        }
      }
      &.has-two-options {
        .container {
          max-width: 82.5rem;
          .flex-wrapper {
            align-items: flex-start;
            .welcome-logo {
              position: relative;
              left: -3.4rem;
              bottom: auto;
              top: -4.8rem;
              @include bp(sm-max) {
                left: -1.6rem;
                top: -2.4rem;
              }              
            }
            .option-selection {
              .title {
                text-align: left;
                margin: 0 0 5.2rem 0;
              }
              .link-button-wrap {
                justify-content: flex-start;
                .link-button {
                  .title {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
     
    }
  }
}

.playground-page{
  .page-filters-wrapper{
    .right-filter{
      max-width: 245px;
      width: 50%;
      .MuiButton-root {
        position: relative;
        top: 0.8rem;
      }
    }
  }
}