.page-header {
  padding: 1.8rem 2.4rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  @include bp(lg-max) {
    padding: 3rem 3rem 0;
  }
  @include bp(sm-max) {
    padding: 2rem 2rem 0;
  }
  .left-header {
    @include bp(lg-max) {
      width: 100%;
    }
    .breadcrumb-wrapper {
      margin-bottom: 0.8rem;
    }
    .title-block {
      h2 {
        margin-bottom: 0.8rem;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $trueGrey;
      }
    }
  }
  .support-block {
    padding: 1.5rem 1.6rem;
    background: $white;
    border: 0.1rem solid $grey8;
    @extend .be-radius;
    display: flex;
    align-items: center;
    width: 30.8rem;
    @include bp(lg-max) {
      order: -1;
      margin-left: auto;
      display: none;
    }
    .support-avatar {
      display: inline-flex;
      height: 6.4rem;
      width: 6.4rem;
      overflow: hidden;
      border-radius: 50%;
    }
    .support-content {
      width: calc(100% - 6.4rem);
      padding-left: 1.6rem;
      font-size: 1.6rem;
      @include line-height(16, 24);
      * {
        display: block;
      }
      .name {
        font-weight: 500;
      }
      a {
        &.tel {
          color: $black;
          &:hover {
            color: $purple;
          }
        }
      }
    }
  }
  &.pricing-header {
    border-bottom: 1px solid $grey2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .getin-touch {
      background-color: $lightPurple;
      height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 340px;
      font-size: 1.4rem;
      color: $black2;
      font-weight: 500;
      margin-left: 1.6rem;
      @include bp(sm-max) {
        margin-left: 0;
        margin-top: 1rem;
      }
      img {
        margin-right: 1rem;
      }
    }
    .right-header {
      display: flex;
      align-items: center;
      @include bp(lg-max) {
        margin: 2rem 0;
      }
      @include bp(sm-max) {
        flex-wrap: wrap;
        margin: 1rem 0 2rem;
      }
    }
  }
}

// breadcumb
.breadcrumb-wrapper {
  @include bp(md-max) {
    padding-top: 4rem;
  }

  .breadcrumb-links {
    @extend .no-bullets;
    display: flex;
    flex-wrap: wrap;
    @include bp(sm-max) {
      padding-right: 15px;
    }
    li {
      margin-right: 1rem;
      font-size: 1.4rem;
      @include line-height(14, 32);
      color: $black1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      @include bp(sm-max) {
        margin-right: 0.5rem;
        line-height: 1.5857142857;
      }
      &:last-child {
        margin: 0;
        color: $purple1;
        display: inline-block;
        text-transform: lowercase;
        &::after {
          display: none;
        }
        &:first-letter {
          text-transform: capitalize;
          display: inline-flex;
        }
      }

      &:first-child {
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }

      &:nth-child(2) {
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }

      &:first-letter {
        text-transform: capitalize;
      }

      &::after {
        content: '>';
        margin-left: 1rem;
        color: $trueGrey;
        display: inline-flex;
        @include bp(sm-max) {
          margin-left: 0.5rem;
        }
      }
      a {
        color: $trueGrey;
        max-width: 28.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline;
        text-transform: lowercase;
        &:first-letter {
          text-transform: capitalize !important;
        }
        &:hover {
          color: $purple1;
        }
      }
      &.cr-pointer {
        text-transform: capitalize;
      }
    }
  }
}

.credits-breacrumb {
  .breadcrumb-wrapper {
    .breadcrumb-links {
      li {
        &:last-child {
          text-transform: capitalize;
        }
      }
    }
  }
}

.main-page-header {
  &.has-bottom-space {
    // margin-bottom: 3.2rem;
  }
  .page-breacrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    position: relative;
    align-items: center;
    @include bp(md-max) {
      align-items: flex-start;
      flex-wrap: nowrap;
    }

    &.has-back-btn {
      justify-content: space-between;
      .is-icon-btn {
        margin-right: 1.6rem;
        background: transparent;
        border: 0;
        @include bp(md-max) {
          margin-top: 1.5rem;
        }
        @include bp(sm-max) {
          margin-right: 0.6rem;
        }
      }
    }
    .compnay-logo {
      padding-right: 0;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 3.2rem;
      width: 10rem;
      @include bp(sm-max) {
        width: 3rem;
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .page-title {
    // margin-bottom:0.5rem;
    // margin-bottom: 1.2rem;
    .title-wrap {
      word-break: break-all;
    }
    h2 {
      margin: 0 0 0.3rem;
      text-transform: lowercase;
      text-transform: none;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    p {
      text-transform: none;
      color: $trueGrey;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .upload-progress {
    margin-bottom: 3.2rem;
    p {
      font-size: 1.4rem;
      @include line-height(14, 20);
      letter-spacing: 0.01em;
      margin-top: 0.8rem;
    }
  }
  .no-data-wrapper {
    margin-top: 8.8rem;
    @include bp(sm-max) {
      margin-top: 7rem;
    }
  }
  .member-heading {
    margin-bottom: 0.5rem;
    p {
      color: $trueGrey;
    }
  }
}

// Checkout steps header
.checkout-steps-header {
  position: fixed;
  left: 5.6rem;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: $grey7;
  @include bp(md-max) {
    left: 0;
  }
  .sidenav-submenu-fixed & {
    left: 26.5rem;
    @include bp(lg-max) {
      left: 5.6rem;
    }
    @include bp(md-max) {
      left: 0;
    }
  }
  .steps-list {
    @extend .no-bullets;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      border-right: 0.1rem solid $grey2;
      @include bp(xs-max) {
        width: 25%;
        &.active {
          width: 50%;
          a {
            .step-name {
              display: block;
            }
          }
        }
      }
      &:last-child {
        border-right: 0;
      }
      a {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $grey5;
        padding: 1rem 4rem;
        position: relative;
        font-weight: 500;
        border-bottom: 0.1rem solid $grey2;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include bp(lg-max) {
          height: 7.5rem;
        }
        @include bp(sm-max) {
          height: 6rem;
          font-size: 1.4rem;
          padding: 1rem 2rem;
        }
        &.active {
          &:after {
            left: 0;
            right: 0;
            width: 100%;
          }
          .step-no {
            color: $black1;
          }
          .step-name {
            color: $black;
          }
        }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          width: 0;
          bottom: 0;
          @extend .transition;
          height: 0.3rem;
          background-color: $purple1;
        }
        .step-no {
          display: block;
          font-size: 1rem;
          @include line-height(10, 16);
          text-transform: uppercase;
        }
        .step-name {
          display: block;
          @include bp(xs-max) {
            display: none;
          }
        }
      }
    }
  }
}

.secondary-header {
  margin-bottom: 0.6rem;
  align-items: start;
  margin-bottom: 0;

  h2 {
    margin-bottom: 0.1rem;
    text-transform: lowercase;
    &:first-letter {
      text-transform: capitalize;
      display: inline-flex;
    }
    &.text-transform-none {
      text-transform: none;
    }
  }
  .right-filter {
    .d-flex {
      &.justify-content-end {
        margin-bottom: 1.6rem;
        position: relative;
        top: 1.4rem;
      }
    }
  }
  .credits-right-filter {
    display: flex;
    @include bp(sm-max) {
      margin-top: 1rem;
    }
    .purple-btn {
      min-height: 4.8rem;
      height: 4.8rem;
      font-size: 1.4rem;
      color: $black2;
      font-weight: 500;
      width: 13rem;
      margin-left: 1.5rem;
      position: relative;
      top: 1rem;
      text-transform: none;
      &.border-btn {
        background-color: $white;
        border: 1px solid rgba(179, 136, 255, 0.3);
      }

      @include bp(smd-max) {
        margin-right: 1.5rem;
        margin-left: 0;
      }
    }
  }
}

.bd-header {
  border-bottom: 1px solid $grey2;
  margin: 0 -2.4rem 2.4rem;
  padding: 0 2.4rem;
  .page-filters-wrapper {
    margin-bottom: 1.4rem;
  }
}
