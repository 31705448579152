.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../images/login-bg.png");
  background-position: bottom right;
  background-size: auto;
  background-repeat: no-repeat;
  &.is-signup {
    flex-wrap: wrap;
    align-items: inherit;
    justify-content: inherit;
    background-image: none;
    .signup-slider {
      width: 42.2%;
      max-height: 100vh;
      background: $lightPurple;
      border-radius: 0 6.4rem 6.4rem 0;
      padding: 10rem 0 2rem;
      position: relative;
      @include bp(smd-max) {
        width: 100%;
        padding-top: 7rem;
        border-radius: 0 0 6.4rem 6.4rem;
      }
      .logo {
        display: inline-flex;
        max-width: 15.4rem;
        margin-bottom: 0;
        position: absolute;
        left: 7.4rem;
        top: 6.2rem;
        @include bp(lg-max) {
          left: 5rem;
          top: 4rem;
        }
        @include bp(sm-max) {
          left: 3rem;
          max-width: 13rem;
          top: 3rem;
        }
      }
      .swiper {
        height: 100%;
        padding: 5rem 0;
        @include bp(sm-max) {
          padding: 3rem 0;
        }
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 7.4rem;
          @include bp(lg-max) {
            padding: 0 5rem;
          }
          @include bp(sm-max) {
            padding: 0;
          }
        }
      }
      .signup-slide {
        .thumb-img {
          text-align: center;
          img {
            @include bp(smd-max) {
              max-width: 35rem;
            }
            @include bp(sm-max) {
              max-width: 25rem;
            }
          }
        }
        .slider-content {
          margin-top: 5.5rem;
          @include bp(smd-max) {
            margin-top: 3rem;
            text-align: center;
            padding: 0 2rem;
          }
        }
      }
    }
    .signup-form-wrapper {
      width: 57.8%;
      @include bp(smd-max) {
        width: 100%;
      }
      .login-wrapper {
        padding: 2rem 0;
        padding: 0;
        @include bp(smd-max) {
          min-height: inherit;
          padding: 5rem 0;
        }
      }
    }
  }
  .login-wrapper {
    width: 60rem;
    max-width: calc(100% - 3rem);
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12rem 0;
    &.lg {
      width: 65.6rem;
    }
    &.secondary {
      align-items: flex-start;
      padding: 8.8rem 0;
      .logo {
        margin-bottom: 14.2rem;
        @include bp(lg-max) {
          margin-bottom: 10rem;
        }
        @include bp(sm-max) {
          margin-bottom: 7rem;
        }
      }
    }
    .login-outer {
      width: 100%;
      .form-group {
        margin-bottom: 3.2rem;
        &.secondary {
          margin-bottom: 2.4rem;
        }
        .form-error {
          margin-top: 0.8rem;
        }
      }
    }
    .checkbox-form-group {
      margin-bottom: 2rem;
    }
    .logo {
      display: block;
      text-align: center;
      // position: fixed;
      // left: 8rem;
      // top: 5rem;
      &.secondary {
        margin-bottom: 3.2rem;
      }
    }
    .signup-title {
      margin-bottom: 3.2rem;
      &.secondary {
        text-align: center;
        h1 {
          margin-bottom: 0.8rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $black1;
          font-weight: 400;
          padding: 0 0;
          &.user-email {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $black1;
            margin-top: 3.2rem;
          }
        }
      }
      h1 {
        margin-bottom: 1.6rem;
        font-size: 2.2rem;
        font-weight: 400;
        @include line-height(22, 32);
        &:last-child {
          margin-bottom: 0;
        }
        &.bold {
          font-weight: 500;
        }
      }
      p {
        font-size: 1.8rem;
        @include line-height(18, 25);
        color: $trueGrey;
      }
      .logo {
        position: relative;
        left: auto;
        top: auto;
        margin: 0 0 3.2rem 0;
      }
    }

    .login-title {
      text-align: center;
      margin-bottom: 4.1rem;
      h1 {
        margin-bottom: 1.3rem;
        letter-spacing: -0.02em;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 28);
      }
    }
    .forgot-link {
      padding: 0 0 2.2rem;
      &.secondary {
        padding: 0;
        margin-top: 3.2rem;
        text-align: center;
      }
      @include bp(sm-max) {
        padding: 0.8rem 0;
      }
      .primary-link {
        text-decoration: none;
        font-weight: 500;
      }
    }
    .inner-form {
      max-width: 47.7rem;
      margin: 0 auto;
      text-align: center;
      &.lg {
        max-width: 52.8rem;
      }
      .reset-link-sent {
        font-size: 1.6rem;
        @include line-height(16, 28);
        margin-top: 6.6rem;
        @include bp(sm-max) {
          margin-top: 4rem;
        }
        p {
          margin: 0;
        }
      }
      .mail-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 2.8rem;
        @include bp(xsm-max) {
          flex-direction: column;
        }
        .MuiButton-root {
          width: calc(50% - 0.8rem);
          height: 4rem;
          @include bp(xsm-max) {
            width: 100%;
            margin-bottom: 1.2rem;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .btn-block {
      margin-top: 1rem;
      &.lg-space {
        margin-top: 3.2rem;
      }
      .MuiButton-root {
        width: 100%;
      }
    }
    .static-link {
      margin-top: 3.4rem;
      .inner-form-link {
        color: $black1;
        text-decoration: underline;
        font-size: 1.6rem;
        @include line-height(16, 28);
        &:hover {
          color: $purple1;
        }
      }
    }
    .primary-link {
      &.is-bottom-sticky {
        position: absolute;
        bottom: 6rem;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }
  }
}