@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';
@import '../../assets/scss/overview-section';

// project - common page for tabs
.project-page-wrap {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    .main-page-header {
      padding-right: 2.4rem;
    }

    .page-filters-wrapper {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      // max-width: 245px;
      // width: 50%;
      //.right-filter {}
    }
    .page-top-navigation {
      ul li a {
        &:hover,
        &.active {
          text-shadow: none;
        }
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    @include bp(smd-max) {
      flex-direction: column;
    }

    // Left page content
    .page-content {
      padding-top: 2.4rem;
      padding-right: 2.4rem;
      width: 100%;
      max-width: calc(100% - 33.4rem);
      @include bp(smd-max) {
        max-width: 100%;
        padding-bottom: 2.4rem;
        .table-wrapper {
          padding-bottom: 2.4rem;
        }
      }
      .page-filters-wrapper {
        padding: 0;
        .left-filter {
          .count {
            font-size: 1.6rem;
            font-weight: 500;
            color: $black1;
          }
          &.btn-wrapper {
            display: flex;
            align-items: center;
            .count {
              margin-right: 0.8rem;
            }
            .btn-plus {
              min-width: 3.6rem;
              .icon {
                height: 3rem;
                font-size: 1.6rem;
              }
            }
          }
        }
        .right-filter {
          @include bp(smd-max) {
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          @include bp(xs-max) {
            flex-wrap: wrap;
            justify-content: unset;
          }
          .form-group {
            @include bp(smd-max) {
              //width: 100%;
              width: calc(100% - 16rem);
              margin: 0 0 1.5rem;
              order: -1;
            }
            @include bp(xs-max) {
              width: 100%;
            }
          }
          .btn-add-members {
            width: auto;
            background: $lightPurple !important;
          }
          .btn-plus {
            min-width: 3.6rem;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .search-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dropdown-text {
              font-size: 1.4rem;
              font-weight: 400;
              color: $black1;
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: center;
              letter-spacing: 0.14px;
              margin: 0 1.4rem 0 0;
              strong {
                font-weight: 500;
              }
              .icon {
                font-weight: 500;
                display: flex;
                align-items: center;
                margin: 0 0 0 0.4rem;
              }
            }
            .MuiFormControl-root {
              @include bp(lg-min) {
                min-width: 30.4rem;
              }
            }
            .borderless-dropdown {
              display: flex;
              width: 18rem;
              max-width: 18rem;
              align-items: flex-start;
            }
          }
          .btn-wrapper {
            display: flex;
            align-items: center;
            background: $lightPurple;
            border-radius: 0.8rem;
          }
        }
      }

      .no-data-wrapper {
        margin-bottom: 2.4rem;

        &.next-idea {
          padding-top: 8rem;
          padding-bottom: 8rem;
          .thumb {
            margin: 0 auto 1.6rem;
          }
          h4 {
            color: $black1;
            font-size: 2rem;
            font-weight: 500;
            @include line-height(20, 32);
          }
          p {
            font-size: 1.6rem;
            @include line-height(16, 24);
          }
          button {
            width: auto;
          }
        }
      }
    }
  }
}

.MuiFilledInput-root {
  &.search-tag-input {
    border: 0;
    padding: 0;
    .MuiInputBase-input {
      color: $black1;
      padding-left: 0;
      &::placeholder {
        color: $grey11;
      }
    }
  }
}

.MuiTableContainer-root {
  .MuiTable-root {
    .MuiTableHead-root {
      .be-down-arrow-1 {
        position: relative;
        top: 3px;
        left: -4px;
        font-size: 1.3rem;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          .user-link {
            padding: 1rem 0;
            display: inline-flex;
            align-items: center;
            font-size: 1.4rem;
            line-height: 1.4rem;
            letter-spacing: 0.01em;
            font-weight: 500;
            color: $black1;
            cursor: pointer;
            &.baseline {
              align-items: baseline;
            }
            .initials,
            .user-avatar {
              @include user-avatar(
                1.2rem,
                400,
                $black1,
                $lightPurple,
                3.4rem,
                3.4rem,
                3.4rem
              );
              img {
                border-radius: 50%;
              }
              &.be-user {
                background-color: $grey6;
                font-size: 1.6rem;
              }
            }
            .user-name {
              padding-left: 1.2rem;
              color: $purple1;
              display: flex;
              align-items: center;
            }
            .MuiButtonBase-root {
              &.btn-star {
                min-width: 2.8rem;
                height: 2rem;
                margin-left: 0.4rem;
                border-radius: 1rem;
                padding: 0;
                &.purple-btn {
                  background: $purple1;
                }
                .icon {
                  line-height: 0;
                  color: $white;
                  font-size: 1.2rem;
                  width: 1.2rem;
                  height: 1.2rem;
                }
              }
            }
          }
          .be-info {
            cursor: pointer;
            position: relative;
            left: 6px;
            top: 3px;
          }
        }
      }
    }
  }
}

// .table-bulk-members-added {
//   overflow-y: auto;
//   @include custom-scroll;
//   max-height: 24rem;
//   padding-top: 3.8rem;
//   .MuiTable-root {
//     .MuiTableRow-root{
//       td {
//         border: 0;
//       }
//     }
//   }
// }

// .custom-dropmenu {
//   .MuiPaper-root {
//     //margin-top: 1.6rem;
//     &.MuiMenu-paper {
//       min-width: 12.2rem;
//       .MuiMenu-list {
//         padding: 0.2rem 0;
//         .MuiMenuItem-root {
//           &:hover {
//             background: transparent;
//           }
//         }
//       }
//       .be-edit {
//         display: flex;
//         margin-right: 0.8rem;
//       }
//     }
//   }
// }

// Create project modal
.create-project-modal {
  .browse-files-link {
    font-weight: 400;
    img {
      margin: 0 0.8rem 0 0;
    }
  }
  .align-space-goal {
    margin: 0.4rem 0 0;
  }
  .advanced-settings {
    margin: 0.8rem 0 0;
  }
}
.advanced-settings {
  .icon {
    color: $black;
  }
  .MuiAutocomplete-root {
    .MuiButtonBase-root {
      &.MuiAutocomplete-clearIndicator {
        display: none;
        svg {
          display: none;
        }
      }
    }
  }
  .MuiPaper-root {
    box-shadow: none;
    background: transparent;
    h4 {
      margin: 0;
    }
    .MuiButtonBase-root {
      background: transparent;
      padding: 0;
      .MuiAccordionSummary-content {
        padding: 0 0 1.8rem 0;
        margin: 0;
      }
    }
    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiAccordionDetails-root {
          padding: 0;
          .form-group {
            margin-bottom: 1.2rem;
            > .MuiFormLabel-root {
              color: $black1;
              font-size: 1.6rem;
              margin: 0 0 0.4rem;
              font-weight: 500;
              display: block;
            }
            .autocomplete-search {
              .MuiFormControl-root {
                .MuiInputBase-root {
                  padding-right: 1.6rem !important;
                  fieldset {
                    legend {
                      span {
                        background-color: transparent;
                        display: none;
                      }
                    }
                  }
                  .icon {
                    height: 1.6rem;
                    color: $black1;
                  }
                }
                //.MuiOutlinedInput-root { border: 0; }
              }
              .search-input-box {
                position: relative;
                &::after {
                  content: '\68';
                  position: absolute;
                  top: 50%;
                  right: 16px;
                  margin-top: -12px;
                  font-size: 2.4rem;
                  color: $black1;
                  cursor: pointer;
                  font-family: 'builder-enterprise' !important;
                  font-style: normal !important;
                  font-weight: normal !important;
                  font-feature-settings: normal !important;
                  font-variant: normal !important;
                  text-transform: none !important;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                }
              }
            }
          }
        }
      }
    }
  }
  .tag-wrapper {
    margin-top: 0.8rem;
    .MuiStack-root {
      .MuiButtonBase-root {
        background: $lightPurple;
        padding: 0.4rem 0.8rem;
        border: 0;
        .MuiChip-label {
          color: $black1;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0 1.2rem 0 0;
          @include line-height(12, 16);
          letter-spacing: 0.012rem;
          min-width: 2rem;
          max-width: 20rem;
        }
        .icon {
          font-size: 1rem;
          color: $black1;
          margin: 4px 0 0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
// Add new goal modal
.add-new-goal-dialog {
  .MuiDialog-container {
    align-items: flex-end;
  }
  .MuiFormLabel-root {
    color: $black2;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 0 0.8rem;
    @include line-height(16, 24);
  }
  .form-group {
    margin-bottom: 2.4rem;
  }
  .label {
    font-weight: 500;
  }
  &.MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 70%;
        .MuiDialogContent-root {
          padding-top: 2.9rem;
        }
      }
    }
  }
}

.owner-dropbox {
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  width: 100%;
  .initials {
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
    border-radius: 50%;
    background: $purple3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: $white;
    margin: 0 1.6rem 0 0;
    text-transform: uppercase;
    cursor: pointer;
  }
  .right-content {
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    max-width: 42rem;
    @extend .truncated-text;
    .owner-name {
      color: $black1;
      font-weight: 500;
      cursor: pointer;
    }
    .email {
      color: $trueGrey;
      cursor: pointer;
    }
  }
}

.MuiPopper-root.MuiAutocomplete-popper {
  .MuiAutocomplete-option {
    flex-flow: column;
    padding: 1.6rem;
  }
}

.playground-page {
  .page-container {
    .main-page-header {
      padding: 0;
      justify-content: space-between;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include bp(md-max) {
        align-items: flex-start;
      }
      .left-page-header {
        display: flex;
        flex-flow: column;
        .breadcrumb-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;
          .page-breacrumb {
            margin-bottom: 0;
          }
        }
        .MuiButtonBase-root {
          margin: 0 1.2rem 0 0;
          &.btn-back {
            // background: $white;
            background: transparent;
            width: 3.2rem;
            height: 3.2rem;
            min-width: 3.2rem;
            @include bp(md-max) {
              margin-top: 3.5rem;
              margin-left: -0.5rem;
            }
            // border: 0.1rem solid rgba(179, 136, 255, 0.30);
            .btn-icon {
              font-size: 1.6rem;
              margin: 0;
            }
          }
        }
      }
      .right-page-header {
        .compnay-logo {
          padding-right: 0;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 3.2rem;
          width: 10rem;
          @include bp(sm-max) {
            width: 4rem;
          }
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
    > .page-filters-wrapper {
      align-items: flex-start;
      .page-title {
        margin-bottom: 0;
        h2 {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
  &.iframe-page-container {
    .page-container {
      padding-top: 0 !important;
      &.border-bottom {
        border-bottom: 1px solid $grey2;
      }
      .main-page-header {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        height: 5.6rem;
        min-height: 5.6rem;
        align-items: center;
        @include bp(md-max) {
          flex-wrap: nowrap;
        }
        .left-page-header {
          .breadcrumb-wrap {
            margin-bottom: 0;
            .btn-back {
              @include bp(md-max) {
                margin: 0 0 0 5.5rem;
              }
            }
            .breadcrumb-wrapper {
              @include bp(md-max) {
                padding-top: 0;
              }
              .breadcrumb-links {
                @include bp(md-max) {
                  padding-right: 0.5rem;
                }
              }
            }
          }
        }
        .mob-header {
          @include bp(md-max) {
            top: 1rem;
          }
        }
      }
      &.sm-x-pad {
        .left-page-header {
          @include bp(md-max) {
            margin-left: 7.5rem;
          }
        }
      }
    }
  }
  .playground-title {
    display: flex;
    align-items: center;
    .MuiButtonBase-root {
      margin-right: 1.6rem;
    }
  }
  .playground-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 2.4rem -2.4rem 0;
    height: calc(100vh - 13.2rem);
    border-top: 0.1rem solid $grey2;
    @include bp(lg-max) {
      height: inherit;
    }
    .col-header {
      height: 6.8rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.4rem;
      border-bottom: 0.1rem solid $grey2;
      background-color: $white;
      &:first-child {
        height: 5.6rem;
      }
      &.grey-bg {
        background-color: $grey1;
      }
      @include bp(smd-max) {
        height: auto;
        padding: 2.4rem;
        &:first-child {
          height: auto;
        }
      }
      &.sm {
        height: 6.8rem;
        h6,
        .serch-count {
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: 0.014rem;
          strong {
            font-weight: 500;
            word-wrap: break-word;
          }
        }
      }
      h5 {
        margin: 0;
      }
      h6 {
        margin: 0;
      }
      .header-left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .templates {
          text-transform: uppercase;
          color: $grey4;
          font-size: 1.2rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          display: flex;
          align-items: center;
          > span {
            display: flex;
            align-items: center;
            padding-left: 0.8rem;
            img,
            em {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 0.8rem;
              display: inline-flex;
              align-items: center;
              &:hover {
                cursor: pointer;
              }
            }
            em {
              font-size: 1.8rem;
            }
          }
        }
        @include bp(smd-max) {
          width: 100%;
        }
      }
      .header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include bp(smd-max) {
          width: 100%;
          margin-top: 1.5rem;
          justify-content: flex-start;
        }
        @include bp(xs-max) {
          flex-direction: column;
          align-items: flex-start;
        }
        .MuiButton-root {
          margin-right: 1.6rem;
          @include bp(xs-max) {
            margin-right: 0;
            margin-bottom: 1rem;
            width: 100%;
          }
          &:hover {
            cursor: pointer;
          }
          &:last-child {
            margin: 0 !important;
          }
        }
        .icon-btn-wrap {
          margin-right: 1.6rem;
          @include bp(sm-max) {
            width: 100%;
            margin-bottom: 1.6rem;
          }
        }
        .grey-btn {
          border: 1px solid rgba(179, 136, 255, 0.3);
          background: $white;
          font-weight: 700;
          &:hover {
            border: 1px solid $purple2;
          }
        }
        .btn-approve {
          font-weight: 700;
          padding-left: 1.7rem;
          padding-right: 1.7rem;
        }
        .button-grouping {
          .MuiButtonBase-root {
            margin: 0;
          }
        }
      }
      .feature-search {
        margin-left: 2.4rem;
        width: 25.8rem;
        max-width: 100%;
        @include bp(xs-max) {
          width: 100%;
          margin: 1rem 0 0;
        }
        .form-group {
          margin: 0;
          .MuiFormControl-root {
            border-color: $grey;
            border-radius: 0.4rem;
            height: 3.6rem;
            input {
              font-size: 1.2rem;
            }
            ::placeholder {
              color: $grey11 !important;
              opacity: 1;
            }
          }
        }
      }
      //Tabs
      .tab-contest {
        .MuiButtonBase-root {
          font-size: 1.6rem;
          min-height: 5.6rem;
          text-transform: capitalize;
          padding-left: 0;
          padding-right: 0;
          min-width: auto;
          margin-right: 4.8rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .col-body {
      height: calc(100% - 12.4rem);
      padding-bottom: 0;
      @include bp(lg-max) {
        height: inherit;
      }
      .no-search-results {
        padding: 13.9rem 0;
        &.sm-pad {
          padding-top: 6.8rem;
          padding-bottom: 6.8rem;
        }
        p {
          padding-left: 0.4rem;
          padding-right: 0.4rem;
          word-wrap: break-word;
        }
      }
      .no-data-block {
        padding: 5.6rem 5.6rem 0;
        @include bp(lg-max) {
          padding: 5rem 2.4rem 0;
        }
        &.is-search-result {
          padding: 0 2.4rem 2.4rem;
          .thumb {
            width: 6rem;
            margin-bottom: 0.8rem;
          }
          .no-data-wrapper {
            padding-top: 6.8rem;
            padding-bottom: 6.8rem;
          }
        }
        .no-data-wrapper {
          &.features-tab-data {
            h4 {
              color: $black1;
              font-size: 2rem;
              line-height: 3.2rem;
            }
            p {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
            button {
              color: $black2;
              .icon {
                color: $black2;
              }
            }
          }
          .sm-font {
            word-break: break-all;
          }
        }
      }
    }
    .drafts-col {
      width: 35.2rem;
      background-color: $white;
      border-right: 0.1rem solid $grey2;
      height: 100%;
      @include bp(lg-max) {
        width: 100%;
        border-right: 0;
        border-bottom: 0.1rem solid $grey2;
        height: inherit;
      }
      .draft-list {
        height: 100%;
        overflow: auto;
        padding: 2.4rem 2.6rem;
        @include custom-scroll;
        @include bp(lg-max) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          height: inherit;
        }
        @include bp(smd-max) {
          padding-bottom: 0;
        }
        .draft-card {
          @include bp(lg-max) {
            width: calc(50% - 1.2rem);
          }
          @include bp(sm-max) {
            width: 100%;
          }
        }
      }
      .col-body {
        height: calc(100% - 5.6rem);
      }
    }
    .features-col {
      width: calc(100% - 35.2rem);
      height: 100%;
      @include bp(lg-max) {
        width: 100%;
        height: inherit;
      }
      .no-data-wrapper {
        padding: 9.9rem 1.5rem;
      }
      .feature-wrapper {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;
        @include bp(lg-max) {
          height: inherit;
        }
        .col-header {
          padding: 0 2.4rem;
        }
        .journey-col {
          width: 24.3rem;
          border-right: 0.1rem solid $grey2;
          height: 100%;
          background: $grey7;
          @include bp(lg-max) {
            height: inherit;
          }
          @include bp(xs-max) {
            width: 100%;
          }
          .journey-list {
            height: calc(100% - 6.8rem);
            overflow: hidden;
            @include custom-scroll;
            &::-webkit-scrollbar-thumb {
              border: 0.2rem solid transparent;
            }
            .journey-outer {
              padding: 2.1rem 2rem 2.1rem 1.6rem;
              display: flex;
              border-bottom: 0.1rem solid #dfe0e6;
              position: relative;
              cursor: pointer;
              @extend .transition;
              &:hover {
                .delete-btn {
                  opacity: 1;
                  visibility: visible;
                }
              }
              .count {
                height: 2.4rem;
                width: 2.4rem;
                min-width: 2.4rem;
                border-radius: 50%;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: #fff;
                background: #83889e;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                outline: 1px solid $white;
              }
              .title {
                width: calc(100% - 4.4rem);
                display: flex;
                padding-left: 0.8rem;
                font-size: 1.4rem;
                line-height: 2rem;
                letter-spacing: 0.014rem;
                align-items: center;
              }
              .delete-btn {
                cursor: pointer;
                position: absolute;
                right: 1.6rem;
                top: 2.6rem;
                width: 1.2rem;
                line-height: 0;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                @extend .transition;
              }
              &.active {
                background-color: $lightPurple;
                .count {
                  background-color: $purple3;
                }
              }
            }
            &:hover {
              overflow: auto;
            }
          }
        }
        .journey-cards-col {
          width: calc(100% - 24.3rem);
          height: 100%;
          @include bp(lg-max) {
            height: inherit;
          }
          @include bp(xs-max) {
            width: 100%;
          }
          .col-header {
            border-bottom: 0;
            background: inherit;
            .serch-count {
              strong {
                word-break: break-all;
              }
            }
          }
          .journey-card-wrapper {
            height: calc(100% - 6.8rem);
            overflow: auto;
            @include custom-scroll;
            padding: 0 2.4rem;
            @include bp(lg-max) {
              height: inherit;
              overflow: visible;
            }
            .card-row {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -1.2rem -2.4rem;
              .card-col {
                padding: 0 1.2rem;
                width: 33.33%;
                margin-bottom: 2.4rem;
                @include bp(xlg-max) {
                  width: 50%;
                }
                @include bp(sm-max) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .discussion-tab-content {
        padding: 2.4rem;
        height: calc(100% + 6.8rem);
        display: flex;
        flex-direction: column;
        .scrollable {
          max-height: 40rem;
          overflow: auto;
          @include custom-scroll;
          @include bp(smd-max) {
            max-height: inherit;
            overflow: visible;
          }
        }
        .activity-group {
          margin-bottom: 2.4rem;
          flex: 1;
          height: 100%;
          max-height: inherit;
          &.outline {
            background: $white;
            outline: 1px solid $grey2;
            padding: 1.6rem;
            border-radius: 0.8rem;
          }
          &:last-child {
            margin: 0;
          }
        }
        h6 {
          padding-bottom: 0.8rem;
          border-bottom: 0.1rem solid $grey3;
          margin: 0;
        }
        .recent-activity {
          height: 100%;
          width: 100%;
          &.no-scroll {
            max-height: inherit;
            overflow: inherit;
          }
          @include bp(smd-max) {
            display: flex;
            flex-flow: column;
          }
          .user-link {
            padding: 2rem 0;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            line-height: 1.4285714286;
            letter-spacing: 0.01em;
            font-weight: 500;
            cursor: pointer;
            position: relative;
            padding-right: 30px;

            .menu-trigger {
              position: absolute;
              right: 0;
              top: 50%;
              cursor: pointer;
              @include translateY(-50%);
              line-height: 0;
              display: inline-block;
              .trigger-icon {
                display: inline-block;
                line-height: 0;
                color: $grey4;
                font-size: 24px;
                @include rotate(90deg);
              }
            }

            .initials,
            .user-avatar {
              @include user-avatar(
                1.2rem,
                500,
                $black1,
                $lightPurple,
                3.6rem,
                3.6rem,
                3.6rem
              );
              img {
                border-radius: 50%;
              }
            }
            .user-name {
              padding-left: 1.2rem;
            }
            .user-detail {
              padding-left: 1.2rem;
              letter-spacing: 0.014rem;
              color: $black;
              font-weight: normal;
              max-width: calc(100% - 5rem);
              .user-name {
                padding-left: 0;
                color: $purple1;
              }
            }
            .time {
              color: $grey5;
              position: relative;
              padding-left: 10px;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 3px;
                width: 3px;
                display: block;
                background: $grey5;
                border-radius: 50%;
              }
            }
            .description-text {
              font-size: 1.4rem;
              line-height: 1.4285714286;
              letter-spacing: 0.01em;
              font-weight: 400;
              word-wrap: break-word;
            }
          }
          .no-data-wrapper {
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-flow: column;
            &.no-discussion {
              padding: 7.15rem 5rem;
              h4 {
                font-size: 2rem !important;
                margin-top: 1.2rem !important;
                margin-bottom: 1.2rem !important;
              }
              p {
                font-size: 1.6rem !important;
              }
            }
          }
        }
        .add-note {
          border-radius: 1.2rem;
          border: 1px solid $grey5;
          padding: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left-content {
            width: 80%;
            input {
              border: 0;
              background: transparent;
              font-size: 1.4rem;
              min-height: 2rem;
              width: 100%;
              &::placeholder {
                color: $grey5;
              }
            }
          }
          .right-content {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include bp(sm-max) {
              width: 10rem;
            }
            .MuiButtonBase-root {
              border: 0;
              background: none;
              height: 3.2rem;
              width: 3.2rem;
              &.be-send-email {
                width: 4rem;
                height: 4rem;
                background: $grey24;
                border-radius: 0.6rem;
                border: 1px solid transparent;
                transition: all ease-in 0.2s;
                &::before {
                  color: $white;
                  transform: rotate(45deg);
                  margin-left: -0.3rem;
                  transition: all ease-in 0.2s;
                }
                &:hover {
                  //border: 1px solid $purple2;
                  background: $purple2;
                  &::before {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
      .blue-btn {
        background-color: $purple;
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        border-radius: 0.4rem;
        height: 3.6rem;
        .icon {
          font-size: 1rem;
          font-weight: 300;
          position: relative;
          top: 0.1rem;
        }
      }

      // Overview tab content wrapper
      .overview-tab-content {
        padding: 2.4rem 2.4rem 0.46rem;
        height: calc(100% + 6.8rem);
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        @include custom-scroll;
        position: relative;
        .inner-container {
          border: 1px solid $grey18;
          border-radius: 0 0 0 2.4rem;
          margin-bottom: 1rem;
          //max-height: 100%;
          .header {
            background: linear-gradient(
              152.16deg,
              #f8f9fa 1.78%,
              #f4f0fc 50.33%,
              #f9f0ee 97.87%
            );
            display: flex;
            justify-content: space-between;
            padding: 2.4rem 2.4rem 1.6rem;
            .left {
              width: calc(100% - 18.8rem);
              .title {
                font-size: 2rem;
                @include line-height(20, 32);
                margin-bottom: 1.6rem;
                word-break: break-word;
                padding-right: 1rem;
              }
              .logo-wrap {
                display: flex;
                align-items: center;
                img {
                  height: 2.4rem;
                  margin-right: 0.8rem;
                  cursor: pointer;
                }
              }
              .count-row {
                padding-top: 4rem;
                display: flex;
                align-items: center;
                .count-col {
                  border-right: 0.1rem solid $grey18;
                  padding: 0 2.4rem;
                  display: flex;
                  flex-flow: column;
                  width: auto;
                  &:first-child {
                    padding-left: 0;
                  }
                  &:last-child {
                    border-right: 0;
                    padding-right: 0;
                  }
                  .title {
                    color: $grey4;
                    font-size: 1.2rem;
                    @include line-height(12, 16);
                    letter-spacing: 0.01rem;
                    text-transform: uppercase;
                    margin-bottom: 0.8rem;
                  }
                  .count,
                  .date {
                    font-size: 1.6rem;
                    @include line-height(16, 24);
                    font-weight: 500;
                  }
                  .review-link {
                    font-size: 1.4rem;
                    margin-left: 0.7rem;
                  }
                }
              }
            }
            .right {
              width: 18.8rem;
              .button-wrap {
                .white-btn {
                  border: 1px solid $purple2;
                  padding: 0.8rem 2.4rem;
                  background: $white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: $black1;
                  font-size: 1.4rem;
                  font-weight: 500;
                  @include line-height(14, 20);
                  transition: all ease-in 0.2s;
                  outline: 1px solid transparent;
                  em {
                    margin-right: 0.8rem;
                    width: 1.6rem;
                    height: 1.6rem;
                  }
                  &:hover {
                    //background: $lightPurple;
                    outline: 1px solid $purple2;
                  }
                }
              }
              .credits-wrap {
                width: 18.8rem;
                padding-top: 2.94rem;
                height: 13.2rem;
                .progress-circle {
                  position: relative;
                  .progress-with-bg {
                    overflow: hidden;
                    max-height: 9.5rem;
                  }
                }
                .credits {
                  position: absolute;
                  bottom: -1.2rem;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 80%;
                  text-align: center;
                  .count {
                    color: $black1;
                    font-size: 2rem;
                    font-weight: 500;
                    @include line-height(20, 32);
                    margin-bottom: 0.4rem;
                  }
                  .title {
                    color: $black1;
                    font-size: 1.2rem;
                    font-weight: 400;
                    @include line-height(12, 16);
                    text-transform: uppercase;
                  }
                }
              }
            }
            @include bp(sm-max) {
              flex-flow: column;
              align-items: center;
              .left {
                width: 100%;
                .title {
                  text-align: center;
                }
                .logo-wrap {
                  justify-content: center;
                }
                .count-row {
                  padding-top: 0;
                  padding-bottom: 1.6rem;
                  flex-flow: column;
                  .count-col {
                    width: 100%;
                    display: block;
                    border-bottom: 1px solid $grey18;
                    border-right: 0;
                    padding: 0.4rem 0;
                    text-align: center;
                  }
                }
              }
              .right {
                width: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .section-wrap {
            padding: 2.4rem 2.4rem 0;
            border-bottom: 1px solid $grey2;
            &:last-child {
              border-bottom: 0;
            }
            .title {
              text-transform: uppercase;
              color: $grey4;
              font-size: 1.2rem;
              @include line-height(12, 16);
              margin-bottom: 2.4rem;
              letter-spacing: 1px;
            }
            // Platforms
            .platforms {
              ul {
                margin: 0;
                padding: 0;
                //display: flex;
                display: grid;
                grid-template-columns: repeat(8, 0fr);

                @include bp(sm-max) {
                  grid-template-columns: repeat(3, 0fr);
                }
                li {
                  list-style: none;
                  border: 1px solid $grey18;
                  border-radius: 0.4rem;
                  width: 8rem;
                  height: 8rem;
                  margin-right: 4rem;
                  margin-bottom: 2.4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-flow: column;
                  background: linear-gradient(
                    152.16deg,
                    #f8f9fa 1.78%,
                    #f4f0fc 50.33%,
                    #f9f0ee 97.87%
                  );
                  img {
                    margin-bottom: 0.65rem;
                    width: 2.4rem;
                    height: 2.4rem;
                  }
                  .title {
                    text-transform: uppercase;
                    color: $black1;
                    font-size: 1.2rem;
                    margin: 0;
                    @include line-height(12, 16);
                  }
                  // &:last-child {
                  //   img {
                  //     transform: scale(1.6);
                  //   }
                  // }
                  @include bp(smd-max) {
                    margin-right: 2.4rem;
                    .title {
                      font-size: 1rem !important;
                    }
                  }
                }
              }
            }

            .phases-and-deliverables {
              position: relative;
              &.shadow-right {
                &::before {
                  content: '';
                  background: url('../../assets/images/bg-slide-shadow.svg')
                    no-repeat 0 0 / cover;
                  height: 100%;
                  width: 7.1rem;
                  height: calc(100% + 3.8rem);
                  position: absolute;
                  top: -2.2rem;
                  right: -2.4rem;
                  z-index: 1;
                }
              }
              .slides-wrap {
                .icon-slide {
                  box-shadow: 0px 2.67px 10.67px 0px #00000026;
                  width: 2.4rem;
                  height: 2.4rem;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  background: $white;
                  border-radius: 100%;
                  cursor: pointer;
                  position: absolute;
                  top: 44.5%;
                  opacity: 0.7;
                  z-index: 4;
                  display: none;
                  @include transition(all ease-in 0.1s);
                  em {
                    height: 2.2rem;
                    &::before {
                      font-size: smaller;
                      margin: 0;
                      color: $black;
                    }
                  }
                  &.left {
                    left: 0.4rem;
                    em {
                      transform: rotate(180deg);
                    }
                  }
                  &.right {
                    right: 0.4rem;
                  }
                  &:hover {
                    opacity: 1;
                    .icon {
                      &::before {
                        color: $black;
                      }
                    }
                  }
                  &.active {
                    display: inline-flex;
                  }
                }
              }
              .inner-content-wrap {
                min-height: 26.2rem;
                margin-bottom: 1.6rem;
                position: relative;
                overflow-y: hidden;
                overflow-x: auto;
                @include custom-scroll;

                .step-box {
                  padding-left: 2rem;
                  border-left: 1px solid $purple3;
                  position: absolute;
                  z-index: 3;
                  &::before {
                    content: '';
                    background: url('../../assets/images/icon-stop-point.svg') 0
                      0 /100%;
                    width: 2.4rem;
                    height: 2.4rem;
                    display: inline-flex;
                    position: absolute;
                    left: -1.2rem;
                  }
                  &.align-top {
                    top: calc(0% + 2.5rem);
                    &::before {
                      bottom: -2.2rem;
                    }
                  }
                  &.align-bottom {
                    top: calc(50% + 1.1rem);
                    &::before {
                      top: -2.2rem;
                    }
                  }
                  img {
                    height: 3.2rem;
                    width: auto;
                  }
                  .step-title {
                    color: $black1;
                    font-size: 1.2rem;
                    @include line-height(12, 16);
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    img {
                      width: auto;
                      height: 1.6rem;
                      margin-left: 0.8rem;
                      margin-top: -2px;
                    }
                  }
                  .step-description {
                    color: $grey4;
                    font-size: 1.2rem;
                    @include line-height(12, 16);
                    min-height: 3.4rem;
                    max-height: 3.4rem;
                    overflow-y: auto;
                    @include custom-scroll;
                  }
                  &.disabled {
                    border-color: $grey18;
                    &::before {
                      content: '';
                      background: url('../../assets/images/icon-stop-point-disabled.svg')
                        0 0 /100%;
                    }
                    .step-title {
                      color: $grey11;
                    }
                    .step-description {
                      color: $grey5;
                    }
                  }
                  // &:first-child {
                  //   width: 16rem;
                  //   left: 2.4rem;
                  // }
                  // &:nth-child(2) {
                  //   width: 23.7rem;
                  //   left: 19.2rem;
                  // }
                  // &:nth-child(3) {
                  //   width: 18.4rem;
                  //   left: 38.4rem;
                  // }
                  // &:nth-child(4) {
                  //   width: 25rem;
                  //   left: 57.6rem;
                  // }
                  // &:nth-child(5) {
                  //   width: 19rem;
                  //   left: 76rem;
                  // }
                  // &:nth-child(6) {
                  //   width: 22rem;
                  //   left: 96rem;
                  // }
                  @include bp(sm-max) {
                    width: 50% !important;
                    &:first-child {
                      left: 4% !important;
                    }
                    &:nth-child(2) {
                      left: 40% !important;
                    }
                    &:nth-child(3) {
                      left: 80% !important;
                    }
                    &:nth-child(4) {
                      left: 120% !important;
                    }
                    &:nth-child(5) {
                      left: 160% !important;
                    }
                    &:nth-child(6) {
                      left: 200% !important;
                    }
                  }
                }
                .horizontal-bar {
                  background-color: $grey25;
                  height: 0.6rem;
                  opacity: 80%;
                  border-radius: 2.4rem;
                  position: absolute;
                  transform: translate(0, -50%);
                  top: 50%;
                  left: 0;
                  width: 100%;
                  z-index: 2;
                  @include bp(sm-max) {
                    width: 242% !important;
                  }
                }
              }
            }

            // Support & Cloud
            .support-and-cloud {
              display: flex;
              padding: 0 0 2.4rem 0;
              .service-box {
                padding: 2.4rem;
                border: 1px solid $purple3;
                margin-right: 2.4rem;
                border-radius: 0 0 0 1.6rem;
                max-width: 50%;
                &.enterprise {
                  border-color: $purple3;
                  img {
                    width: 17.639rem;
                  }
                }
                &.cloud {
                  border-color: $purple8;
                  img {
                    width: 11.6rem;
                  }
                }
                .logo {
                  margin-bottom: 0.8rem;
                }
                .text-description {
                  color: $grey4;
                  font-size: 1.2rem;
                  @include line-height(12, 16);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Playground Welcome page
.playground-welcome-page {
  .inner-content {
    .welcome-content {
      .container {
        .flex-wrapper {
          //.welcome-logo {}
          .option-selection {
            .title {
              font-size: 2.8rem;
              font-weight: 500;
              @include line-height(28, 36);
            }
            //.link-button-wrap {}
          }
        }
      }
    }
  }
}

// Project overview tab page
//.project-overview-tab-page {}

// Playground meeting
.meeting-playground-page {
  // .page-container {
  //   .main-page-header {
  //     .page-breacrumb {
  //       margin: 0 0 0.8rem 0;
  //     }
  //     .page-title {
  //       margin: 0;
  //     }
  //   }
  // }
  .page-title {
    padding: 1.6rem 2.4rem;
    margin: 0;
    h6 {
      font-size: 1.6rem;
      line-height: 2.4rem;
      display: flex;
      align-items: center;
      margin: 0;
      &:first-letter {
        text-transform: uppercase;
      }
      .icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
        margin-top: -0.2rem;
      }
    }
  }
  .iframe-wrapper {
    width: 100%;
    height: calc(100vh - 6.3rem);
  }
  .footer-section {
    display: flex;
    justify-content: flex-end;
    .assigned-buildcard {
      width: 100%;
      max-width: 69rem;
      padding: 3.4rem 2.4rem 2.4rem;
      margin-right: 2.4rem;
      border-radius: 2rem 0 2rem 0;
      background: $white;
      box-shadow: 0 1.2rem 3rem 0 rgba(101, 31, 255, 0.1);
      .table-wrapper {
        &.assigned-buildcard-table {
          .MuiTableContainer-root {
            .MuiTable-root {
              .MuiTableHead-root {
                .MuiTableRow-root {
                  border: 0;
                  .MuiTableCell-root {
                    border: 0;
                    background-color: $white;
                    height: auto;
                    color: $trueGrey;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 500;
                    @include line-height(12, 16);
                    letter-spacing: 0.1rem;
                    text-transform: uppercase;
                  }
                }
              }
              .MuiTableBody-root {
                .MuiTableRow-root {
                  border: 0;
                  .MuiTableCell-root {
                    border: 0;
                    background-color: $white;
                    height: auto;
                    color: $black1;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 500;
                    @include line-height(18, 28);
                    span,
                    a {
                      color: $purple1;
                    }
                    .link-wrap {
                      .title {
                        color: #6200ea;
                        font-size: 1.8rem;
                        font-weight: 500;
                        @include line-height(18, 28);
                        max-width: 27rem;
                        @extend .truncated-text;
                      }
                      .primary-link {
                        margin: 0 0 0 1.6rem;
                        color: #6200ea;
                        font-size: 1.2px;
                        font-weight: 400;
                        @include line-height(12, 16);
                        letter-spacing: 0.012rem;
                        text-decoration-line: underline;
                        &:hover {
                          text-decoration: none;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Modals [Dialogues]
.call-may-team-dialogue {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiDialogContent-root {
        padding: 2.4rem 2.4rem 1.6rem;
      }
    }
  }
  .tab-content-wrapper {
    .MuiBox-root {
      .MuiTabs-root {
        .MuiTabs-fixed {
          .MuiTabs-flexContainer {
            .MuiButtonBase-root {
              text-transform: none;
              font-weight: normal;
            }
            .MuiTab-root {
              &.Mui-selected {
                font-weight: 500;
              }
            }
          }
          //.MuiTabs-indicator {} // bottom-border
        }
      }
    }
    // Tab content sections
    //.MuiTabPanel-root {}
    .tab-content-section {
      .title-block {
        margin: 0 0 2.4rem;
        .left-title {
          display: flex;
          align-items: center;
          .MuiSwitch-root {
            margin-right: 0.8rem;
          }
          .enable-natasha-text {
            color: $black1;
            font-size: 1.4rem;
            font-weight: 500;
          }
          img.natasha-icon {
            margin-left: 0.8rem;
            width: 2.4rem;
            height: 2.4rem;
          }
          .be-info {
            color: $grey11;
            font-size: 1.4rem;
            display: flex;
            margin-left: 0.3rem;
            cursor: pointer;
          }
        }
      }
      .info-title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0.4rem 0;
        h6 {
          margin: 0;
        }
      }
      .row {
        .form-group {
          margin-bottom: 2.4rem;
          h6 {
            margin: 0 0 0.4rem;
          }
          .select-box {
            width: 100%;
          }
        }
      }
      .searchbar-wrap {
        .MuiFormControl-root {
          .MuiInputBase-root {
            display: flex;
            flex-flow: row-reverse;
            height: 6rem;
            .MuiInputAdornment-root {
              margin: 0 1.6rem 0 0;
            }
          }
        }
        .chips-wrapper {
          margin: 0.8rem 0;
          .MuiStack-root {
            .MuiButtonBase-root {
              @extend .transition;
              margin: 0.8rem 0.4rem;
              position: relative;
              background: $lightPurple;
              padding: 0.4rem 0.8rem;
              .MuiChip-label {
                transition: all 0.2s ease-in;
                padding: 0.2rem 0.8rem;
                color: $black1;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12, 16);
                letter-spacing: 0.012rem;
              }
              .MuiChip-deleteIcon {
                margin: 0 0;
                @extend .transition;
              }
              .icon {
                font-size: 1rem;
                color: $black1;
                margin: 4px 0 0;
              }
              &:hover {
                cursor: pointer;
                background: $lightPurple;
              }
            }
          }
        }
      }
    }
  }
}

// Todo : to be removed later
.pt-0 {
  .no-data-wrapper {
    margin-top: 4rem;
  }
}

//Remove journey modal
.remove-btn {
  color: $black1;
  background: $red1 !important;
  &:hover {
    background: $red1;
  }
}

.selected-tags .sm-close {
  font-size: 1rem !important;
  display: inline-flex;
  color: $black1 !important;
}
.total-count {
  font-size: 1.4rem;
  color: $trueGrey;
  font-weight: 400;
  margin-bottom: 1rem;
  display: block;
}
.features-list {
  .MuiMenu-paper {
    min-width: 15.55rem !important;
    ul {
      li {
        font-size: 1.2rem;
      }
    }
  }
}

.overview-change-requests {
  border: 0.1rem solid $grey18;
  border-radius: 0 0 0 2.4rem;
  margin: 0 0 2.4rem;
  max-height: calc(100% - -3.2rem);
  overflow: auto;
  @include custom-scroll;
  .overview-header {
    background-color: #fbf8ff;
    border-bottom: 0.1rem solid $grey18;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    @include bp(lg-max) {
      flex-wrap: wrap;
    }
    .MuiChip-root {
      height: 2rem;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.6;
      letter-spacing: 0.1rem !important;
      color: $white;
      margin: 0 0.8rem 0 0.8rem;
      span {
        letter-spacing: 0.1rem;
      }
      &.purple {
        background-color: #6200ea !important;
      }

      &.yellow {
        background-color: $amber;
      }
      &.green {
        background-color: #00c853 !important;
      }
      &.red {
        background-color: $red3;
      }
    }
    .title {
      margin-bottom: 1.5rem;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      h4 {
        margin-bottom: 0;
      }
      .be-info {
        display: flex;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }
  .new-journeys-block {
    border-bottom: 0.1rem solid $grey18;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    .white-btn {
      padding: 0 !important;
      width: 3.6rem;
      min-width: 3.6rem;
      font-size: 1rem !important;
    }
    &.platforms-block {
      border-radius: 0 0 0 2.4rem;
      border: 0;
      img {
        margin-right: 0.8rem;
        height: 2.4rem;
        width: 2.4rem;
      }
      p {
        margin-right: 2.4rem;
        margin-top: 1rem;
        margin-bottom: 0;
      }
      .bd-top {
        border-top: 0.1rem solid $grey18;
      }
      &:last-child {
        border-top: 0.1rem solid $grey18;
      }
    }
  }
  .overview-table-outer {
    display: none;
    &.active {
      display: block;
    }
  }
  .overview-users-info {
    .overview-table {
      background-color: #f9fafb;
      padding: 0.9rem 1.6rem;
      border-bottom: 0.1rem solid $grey8;
      border-radius: 0;
      thead {
        th {
          border: 0;
          font-size: 1.4rem;
          color: $black1;
          font-weight: 500;
          text-transform: none;
          letter-spacing: 0.03rem;
        }
      }
      tr {
        td {
          font-size: 1.4rem;
          color: $black1;
          font-weight: 400;
          padding: 0.4rem 1.5rem;
          .borderless-dropdown {
            .MuiSelect-select {
              width: auto !important;
              padding: 0 2.7rem 0 1rem !important;
              height: 3.2rem !important;
            }
            &.Mui-focused {
              .MuiSelect-select {
                background-color: $purple11;
                border: 0.1rem solid rgba(217, 196, 255, 1);
              }
            }
          }
          em {
            color: $grey21;
          }
          .currencies {
            display: flex;
            justify-content: flex-end;
            .builder-coin {
              width: 1.7rem;
              height: 1.7rem;
              padding: 0.4rem;
              margin-left: 0.5rem;
            }
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      table {
        @include bp(sm-max) {
          width: 760px;
        }
      }
    }
  }
  .left {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      li {
        border-right: 0.1rem solid $grey18;
        padding-right: 2.5rem;
        margin-right: 2.5rem;
        @include bp(sm-max) {
          padding-right: 1.5rem;
          margin-right: 1.5rem;
        }
        span {
          color: $grey26;
          font-size: 1.2rem;
          font-weight: 400;
          text-transform: uppercase;
          display: flex;
          margin-bottom: 0.6rem;
          letter-spacing: 0.1rem;
        }
        p {
          font-size: 1.6rem;
          font-weight: 500;
          color: $black1;
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          border-right: 0;
        }
      }
    }
  }

  .right {
    .credits-required {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      color: $grey26;
      font-weight: 400;
      justify-content: end;
      margin-bottom: 1.8rem;
      @include bp(sm-max) {
        justify-content: start;
        margin-top: 1rem;
      }
      strong {
        font-weight: 500;
        color: $black1;
        margin: 0 0.5rem;
      }
    }
    .btn-group {
      display: flex;
      justify-content: end;
      @include bp(sm-max) {
        flex-wrap: wrap;
        justify-content: start;
      }
      .white-btn {
        border: 0.1rem solid rgba(179, 136, 255, 0.3);
        border-radius: 0.8rem;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 1.6rem;
        color: $black1;
        @include bp(sm-max) {
          margin-bottom: 0.8rem;
        }
        &:hover {
          border-color: $purple2;
        }
        &.active {
          &:before {
            transform: rotate(180deg);
          }
        }
        .icon {
          font-size: 1rem;
          font-weight: 300;
          position: relative;
          top: 0.1rem;
        }
      }
      button {
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
      * {
        // margin-left: 0rem !important;
      }

      &.change-request {
        button {
          margin-left: 1.6rem;
          margin-right: 0;
        }
      }
    }
  }
  .builder-coin {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 10rem;
    display: inline-flex;
    border: 0.1rem solid rgba(179, 136, 255, 1);
    color: $bcoin;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}
.discard-modal,
.customiz-dialog {
  .MuiDialog-paper {
    width: 57.4rem !important;
    max-width: 57.4rem !important ;
    h2 {
      margin-bottom: 1rem;
    }
  }
}

.approve-dialog {
  .warning-note {
    background: $orange2;
    padding: 1.1rem 1.2rem;
    border-left: 0.4rem solid $amber;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    color: $black1;
    margin: 2rem 0 0;
    letter-spacing: 0.01em;
  }
  .system-msg {
    padding: 1.1rem 1.2rem;
    margin-top: 1rem;
    p {
      font-size: 1.2rem;
      @include line-height(12, 16);
      color: $red;
    }
  }
}
