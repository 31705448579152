@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';
@import '../../assets/scss/overview-section';

.supply-chain-distribution {
  &.owner-overview-page {
    // TODO : move to respective section
    .page-container {
      padding-right: 4.8rem;
      .compnay-logo {
        text-align: right;
        img {
          max-width: 7.9rem;
        }
      }
    }
    .overview-page-row {
      .overview-left {
        .overview-welcome {
          .overview-container {
            align-items: flex-start;
            .left-content {
              padding: 0;
              .boxed-title-block {
                .boxed-title {
                  font-size: 4.4rem;
                }
              }
            }

            .video-wrapper {
              width: 54%;
              @include bp(smd-max) {
                width: 100%;
                margin-top: 3rem;
              }
            }
          }
        }
        .no-data-wrapper {
          .grey-btn {
            min-width: 18rem;
          }
        }
      }

      .overview-right {
        .onboarding-steps {
          li {
            margin-bottom: 1.2rem;
            a {
              position: relative;
              background: rgba($grey14, 0.5);
              &.active {
                background: $grey14;
              }
              .step-close-btn {
                position: absolute;
                right: 1.8rem;
                top: 3rem;
                line-height: 0;
                font-size: 1.8rem;
                z-index: 1;
                cursor: pointer;
                &::before {
                  font-weight: 600 !important;
                }
              }
              .step-content {
                width: calc(100% - 6rem);
                .sub-title {
                  color: $grey9;
                }
              }
            }
          }
        }
      }
    }
  }
}

.space-overview-page {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    @include bp(sm-max) {
      padding-right: 1.6rem;
    }
    .main-page-header {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      @include bp(sm-max) {
        padding-right: 0;
      }
    }
    .page-filters-wrapper {
      padding: 0;
      margin: 0 0 1.6rem 0;
      @include bp(sm-max) {
        position: static;
      }
      .left-filter {
        &.btn-wrapper {
          display: flex;
          align-items: center;
          .count {
            margin-right: 1.2rem;
            color: $black1;
          }
          .btn-plus {
            min-width: 3.6rem;
            .icon {
              height: 3rem;
              font-size: 1.6rem;
            }
          }
        }
      }
      .right-filter {
        @include bp(smd-max) {
          flex-wrap: nowrap;
          justify-content: flex-end;
        }
        @include bp(xs-max) {
          flex-wrap: wrap;
          justify-content: unset;
        }
        .form-group {
          @include bp(smd-max) {
            //width: 100%;
            width: calc(100% - 16rem);
            margin: 0 0 1.5rem;
            order: -1;
          }
          @include bp(xs-max) {
            width: 100%;
          }
        }
        .btn-filter-by {
          min-width: 3.6rem;
          height: 3.6rem;
          background: $lightPurple;
          border: 1px solid transparent;
          position: relative;
          .count {
            position: absolute;
            top: -0.7rem;
            right: -0.7rem;
            height: 1.4rem;
            min-width: 1.4rem;
            padding: 0 0.2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $purple1;
            border-radius: 50%;
            font-size: 1rem;
            @include line-height(10, 16);
            font-weight: 500;
            color: $white;
          }
          .be-filter-by {
            display: inline-flex;
          }
          &:hover {
            border: 1px solid $purple2;
          }
        }
        .btn-add-members {
          width: auto;
          background: $lightPurple !important;
        }
        .btn-plus {
          min-width: 3.6rem;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .search-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .dropdown-text {
            font-size: 1.4rem;
            font-weight: 400;
            color: $black1;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.14px;
            margin: 0 1.4rem 0 0;
            strong {
              font-weight: 500;
            }
            .icon {
              font-weight: 500;
              display: flex;
              align-items: center;
              margin: 0 0 0 0.4rem;
            }
          }
          .MuiFormControl-root {
            @include bp(lg-min) {
              min-width: 30.4rem;
            }
          }
          .borderless-dropdown {
            display: flex;
            width: 18rem;
            max-width: 18rem;
            align-items: flex-start;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          background: $lightPurple;
          border-radius: 0.8rem;
        }
      }
    }
    .overview-inner-section {
      display: flex;
      justify-content: space-between;
      @include bp(smd-max) {
        flex-wrap: wrap;
      }
      .page-content {
        padding-top: 2.4rem;
        padding-right: 2.4rem;
        padding-bottom: 0;
        width: 100%;
        max-width: calc(100% - 33.4rem);
        @include bp(smd-max) {
          max-width: 100%;
        }
        @include bp(sm-max) {
          padding-right: 0;
        }
      }
    }
  }

  // Grid and List view Tabs
  .grid-list-tabs {
    .MuiTabs-root {
      min-height: 3.6rem;
      min-width: 5.2rem;
      background: $lightPurple;
      border-radius: 0.8rem;
      .MuiButtonBase-root {
        min-height: inherit;
        min-width: inherit;
        background: $grey14;
      }
    }
  }
}

.overview-scroll-area {
  max-height: calc(100vh - 21.4rem);
  overflow: hidden auto;
  @include bp(smd-max) {
    max-height: 100%;
  }
  @include bp(sm-max) {
    max-height: initial;
    overflow: visible;
  }
  @include custom-scroll;
}

.accordion-table-holder {
  @extend .overview-scroll-area;
  .MuiTable-root {
    .MuiTableRow-root {
      td {
        border: 0 !important;
      }
    }
  }
  .MuiAccordionSummary-root {
    min-height: 6.4rem;
    &.Mui-expanded {
      .space-ac-wrapper {
        .ac-left {
          .arrow-icon {
            @include rotate(90deg);
          }
        }
      }
    }
  }
  .table-wrapper {
    border-radius: 0 0 0.8rem 0.8rem;
  }
  .space-ac-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .ac-left {
      display: inline-flex;
      align-items: center;
      width: 40%;
      @include bp(xlg-max) {
        width: 100%;
      }
      .arrow-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        font-size: 1rem;
        @extend .transition;
      }
      .text-lable {
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 0.8rem;
        font-weight: 500;
        max-width: calc(100% - 2rem);
        color: $black1;
        text-transform: capitalize;
      }
      .rating {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .ac-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      width: 60%;
      @include bp(xlg-max) {
        width: 100%;
        justify-content: flex-start;
        padding: 1.5rem 0 0 2.8rem;
      }
      .inner-data {
        margin-right: 1.6rem;
        &:last-child {
          margin-right: 0;
        }
        .primary-link {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        .tag-wrapper {
          display: flex;
          align-items: center;
          .custom-tag {
            margin-right: 0.8rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .custom-tag {
          line-height: 2.4rem;
          white-space: nowrap;
          text-transform: none;
          font-size: 1.2rem;
          letter-spacing: 0.012rem;
          max-width: 16.5rem;
          @extend .truncated-text;
          cursor: pointer;
        }
        .counts {
          display: inline-flex;
          align-items: center;
          color: $grey4;
          font-weight: 500;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          .value {
            color: $black;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
  .accordion-head-table-wrap {
    .MuiTableContainer-root {
      // overflow: hidden;
      @include bp(sm-max) {
        overflow: auto;
      }
    }
  }
  .list-view {
    margin-top: 0;
  }
}

// Accordion Wrapper
.accordion-wrapper {
  margin: 0 0 1.6rem 0;
  .MuiAccordionSummary-content {
    margin: 0.8rem 0;
  }
  > .MuiPaper-root {
    margin: 0 0 2.4rem 0;
    border-radius: 0.8rem;
    border: 0px solid $grey2;
    background: $white;
    box-shadow: none;
    z-index: 1;
    > .MuiButtonBase-root {
      &:hover {
        border-radius: inherit;
      }
    }
  }

  // Accordion head table wrapper
  .accordion-head-table-wrap {
    padding: 0;
    background: $white;
    > .MuiTableContainer-root {
      > .MuiTable-root {
        > .MuiTableBody-root {
          > .MuiTableRow-root {
            > .MuiTableCell-root {
              color: $black;
              font-size: 1.2rem;
              border: 0;
              .user-link {
                text-transform: capitalize;
                border: 0;
                color: $black1;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                @include line-height(16, 24);
                display: flex;
                align-items: center;
                .icon {
                  line-height: 0;
                }
                .text-lable {
                  padding-left: 0.8rem;
                  letter-spacing: 0.014rem;
                  @include line-height(14, 20);
                }
                img {
                  padding-left: 0.8rem;
                }
              }
              .number-title {
                text-transform: uppercase;
                color: $grey4;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12, 16);
              }
              .MuiStack-root {
                .MuiChip-root {
                  margin-right: 2.4rem;
                  .MuiChip-label {
                    font-weight: 500;
                  }
                }
              }
              a {
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // Expended accordion list table data

  // Expended list table-wrap
  .expended-list-table-wrap {
    > .MuiTableContainer-root {
      > .MuiTable-root {
        > .MuiTableHead-root {
          > .MuiTableRow-root {
            > .MuiTableCell-root {
              border: 0;
              color: $grey11;
              font-size: 10px;
              font-weight: 500;
              @include line-height(10, 16);
              letter-spacing: 0.1rem;
              text-transform: uppercase;
              background: $grey1;
              text-align: left;
              &:first-child {
                padding: 0 0 0 1.6rem;
                text-align: left;
              }
            }

            // Grid view cards wrapper
            .grid-view-cards-wrapper {
              padding:0rem;
              .data-container {
                margin-bottom: -1.6rem;
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                .card-template {
                  margin-bottom: 1.6rem;
                  background: linear-gradient(102deg,#fbf9ff .94%,#f5eefe 74.96%,rgba(243,235,255,0) 155.42%);
                  display: flex;
                  flex-direction: column;
                  height: calc(100% - 1.6rem);
                  &:hover {
                    border: 1px solid $purple2;
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);                    
                  }
                  .card-body {
                    background: transparent;
                    flex: 1;
                    .tag-container {
                      padding: 0 0 .8rem 0;
                      .MuiStack-root {
                        .MuiChip-root {
                          height: 2rem;
                          padding: 0;
                          .MuiChip-label {
                            padding: 0 0.8rem;
                          }
                        }
                      }
                    }   
                    .button-wrap{
                      margin-left: 0.8rem;
                    .three-dot-btn{
                      background-color: $white !important;
                      border:1px solid rgba(179, 136, 255, 0.3);
                      border-radius: 0.4rem !important;
                      width: 2.4rem !important;
                      height: 2.4rem !important;
                      .icon{
                      &:before{
                        font-size: 1.6rem!important;
                      }
                    }
                  }
                    }                 
                  }
                  .card-footer {
                    background: $white;
                    @extend .be-radius;
                    margin-top: auto;
                  }
                }
              }
              &:last-child {
                padding: 0 1.6rem 0 0;
              }
              .icon {
                &::before {
                  color: $grey11;
                }
              }
            }
          }
        }
        > .MuiTableBody-root {
          > .MuiTableRow-root {
            > .MuiTableCell-root {
              border: 0;
              color: $black1;
              font-size: 1.4rem;
              font-weight: 400;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              text-align: left;
              &:first-child {
                padding: 0 0 0 1.6rem;
                text-align: left;
              }
              &:last-child {
                padding: 0 1.6rem 0 0;
              }
              //Tags
              .MuiStack-root {
                display: inline-block;
                .MuiChip-root {
                  padding: 0;
                  height: 2.4rem;
                  width: 13.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @extend .truncated-text;
                  .MuiChip-label {
                    color: $white;
                    font-size: 1rem;
                    font-weight: 500;
                    @include line-height(10, 16);
                    letter-spacing: 0.1rem;
                    text-transform: uppercase;
                  }
                }
              }

              .progress-circle {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.5rem;
                cursor: pointer;
                .progress-bar {
                  height: 10px;
                }
                &:hover {
                  .credits {
                    display: block;
                  }
                }
              }
              .MuiButtonBase-root {
                &.is-icon-btn {
                  &.purple-btn {
                    width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 0.4rem;
                    font-size: 1rem;
                    font-weight: 400;
                    .be-tag-pin {
                      width: 1.2rem;
                      &::before {
                        font-size: 1.2rem;
                      }
                    }
                  }
                }
              }
            }
            &:last-child {
              .MuiTableCell-root {
                border: 0;
              }
            }
          }
        }
      }
    }
  }

  // Grid view cards wrapper
  .grid-view-cards-wrapper {
    padding: 0rem;
    .data-container {
      margin-bottom: -1.6rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      .card-template {
        margin-bottom: 1.6rem;
        background: linear-gradient(
          102deg,
          #fbf9ff 0.94%,
          #f5eefe 74.96%,
          rgba(243, 235, 255, 0) 155.42%
        );
        display: flex;
        flex-direction: column;
        height: calc(100% - 1.6rem);
        &:hover {
          border: 1px solid $purple2;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
        }
        .card-body {
          background: transparent;
          flex: 1;
          .tag-container {
            padding: 0 0 0.8rem 0;
            .MuiStack-root {
              .MuiChip-root {
                height: 2rem;
                padding: 0;
                .MuiChip-label {
                  padding: 0 0.8rem;
                }
              }
            }
          }
        }
        .card-footer {
          background: $white;
          @extend .be-radius;
          margin-top: auto;
        }
      }
      .isArchived {
        opacity: 0.6;
      }
    }
  }
  &.hidden {
    overflow: hidden;
  }
}

// Accordion content wrapper : Filtered cards
.accordion-filtered-cards-wrap {
  @extend .overview-scroll-area;
  .accordion-wrapper {
    .MuiPaper-root {
      margin: 0 0 2rem 0;
      &::before {
        display: none;
      }
      .MuiAccordionSummary-root {
        min-height: 3.6rem;
        background: $white;
        border-radius: 0.4rem;
        padding: 0 1.6rem 0 1.2rem;
        border-left: 0.4rem solid $grey2;
        //&.Mui-expanded {}
        &.status-running {
          border-left: 0.4rem solid $purple1;
        }
        &.status-pending-approval {
          border-left: 0.4rem solid $amber;
        }
        &.status-ready-for-kickoff {
          border-left: 0.4rem solid $purple;
        }
        &.status-delovered {
          border-left: 0.4rem solid $green1;
        }

        .MuiAccordionSummary-content {
          &.Mui-expanded {
            margin: 0;
          }
          .accordion-title {
            margin: 0;
            font-size: 1.4rem;
            @include line-height(14, 20);
            color: $black1;
          }
        }
        .MuiAccordionSummary-expandIconWrapper {
          .be-chevron-down {
            color: $black;
            height: 2rem;
            width: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &::before {
              font-size: 1.2rem !important;
            }
          }
        }
      }
      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          .MuiCollapse-wrapperInner {
            .MuiAccordionDetails-root {
              padding: 2rem 0 0;
            }
          }
        }
      }
    }
  }
}

// Tooltip popover
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    width: auto;
    min-width: auto;
    border-radius: 8px;
    box-shadow:
      0px 1px 4px 0px rgba(0, 0, 0, 0.08),
      0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  }
}

.taglist-menu {
  .MuiMenu-list {
    padding: 1.6rem;
    .tag-row {
      margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
      .custom-tag {
        @extend .truncated-text;
        display: block;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-transform: none;
        letter-spacing: 0.012rem;
        cursor: pointer;
      }
    }
  }
}

.page-filters-wrapper {
  .right-filter {
    .search-input {
      width: 30.4rem;
      max-width: 100%;
      @include bp(sm-max) {
        width: 63%;
      }
    }
    .button-grouping {
      max-height: 3.6rem;
    }
  }
}

.spaces-overview-outer {
  table {
    th {
      background: $grey6 !important;
      height: 3rem !important;
    }
  }
  .MuiPaper-rounded {
    border-radius: 0 !important;
    background: transparent;
  }
  .card-tags-wrap.list-view {
    margin-top: 0 !important;
    float: left;
    .MuiButton-root.xs-btn.is-icon-btn {
      background-color: $white;
      border-color: $purple2;
      border-radius: 0.4rem;
      margin-bottom: 0;
      &:hover {
        background-color: $lightPurple;
      }
    }
  }
  .MuiTable-root {
    .MuiTableCell-body {
      height: 6rem;
    }
  }

  table {
    margin-bottom: 3px;
  }
  .table-body {
    tr {
      border: 1px solid transparent;
      border-bottom: 1px solid $grey2;
      outline: 1px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      &:hover {
        background-color: $lightPurple1 !important;
        border-bottom: 1px solid transparent;
        outline: 2px solid $purple2;
        border-left: 2px solid $purple2;
        border-right: 2px solid $purple2;
        cursor: pointer;
      }
      &.isArchived {
        opacity: 0.6;
      }
    }
  }
  .accordion-table-holder .table-wrapper {
    border-radius: 0;
  }
  .build-icon {
    display: inline-flex;
    margin: 0 1rem 0 0;
    float: left;
    img {
      width: 18px;
    }
  }
  .card-title {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .spacegoals {
    .icon-btn-wrap {
      display: flex;
      justify-content: start;
      .btn-goal-rounded {
        background-color: $white;
        border-radius: 0.4rem;
        width: 2.4rem;
        min-width: 2.4rem;
        font-size: 1rem;
        color: $black1;
        line-height: 2rem;
        font-weight: 400;
        em {
          margin-right: 0;
          line-height: 1.2rem;
        }
        &:hover {
          background-color: $lightPurple;
        }
      }
    }
  }
  .platform {
    color: $purple4 !important;
  }
}

ul.platform-name {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -1rem;
  li {
    margin: 0 0.4rem;
    a {
      border: 1px solid $grey16;
      font-size: 1.2rem;
      color: $black1;
      font-weight: 500;
      height: 2rem;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      background-color: $grey15;
      border-radius: 3.5rem;
    }
  }
}

.no-buildcard {
  .btn-add-goals {
    width: auto !important;
  }
}

.tabListView,
.activities-wrapper,
.summary {
  overflow: hidden auto;
  @include custom-scroll;
  max-height: calc(100vh - 22.2rem);
  @include bp(smd-max) {
    max-height: calc(100vh - 32.9rem);
  }
}

.activities-wrapper {
  max-height: calc(100vh - 24.6rem);
}

.spaces-credits-page {
  .tabListView {
    max-height: calc(100vh - 16.2rem);
    padding-bottom: 1.6rem;
  }
}

.btn-start-working-idea {
  order: 1;
  margin-right: 0 !important;
  margin-left: 1.6rem !important;
}
