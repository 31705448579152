@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/extended-classes";
@import "../../assets/scss/break-points";

.plan-checkout-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10rem;
  align-items: flex-start;
  overflow: hidden;
  height: 100vh;
  @include bp(lg-max) {
    padding-top: 7.5rem;
  }
  @include bp(smd-max) {
    overflow: inherit;
  }
  .checkout-content {
    width: calc(100% - 33.5rem);
    padding: 2.5rem 0;
    height: calc(100vh - 10rem);
    @include custom-scroll;
    overflow: auto;
    @include bp(lg-max) {
      width: calc(100% - 30rem);
      height: calc(100vh - 7.5rem);
    }
    @include bp(smd-max) {
      width: 100%;
      height: auto;
      overflow: inherit;
      padding: 3rem 0;
    }
    .checkout-page-title {
      margin-bottom: 4rem;
      @include bp(lg-max) {
        margin-bottom: 2.5rem;
      }
    }
    .payment-container {
      max-width: 75%;
      @include bp(xl-max) {
        width: 76rem;
        max-width: 100%;
      }
      @include bp(lg-max) {
        width: 100%;
      }
      .payment-method {
        margin-bottom: 4rem;
      }
      .payment-method-details {
        .add-cc-link {
          display: inline-flex;
          align-items: center;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 500;
          margin-top: 1.9rem;
          .be-add-circle {
            font-size: 2rem;
            line-height: 1;
            margin-right: 0.8rem;
          }
        }
        .credit-card-listing {
          padding: 0.8rem 2.4rem;
          border: 0.1rem solid $grey8;
          @extend .be-radius;
          .inner-listing {
            .inner-row {
              padding: 0.55rem 0;
              display: flex;
              width: 100%;
              &:last-child {
                border-bottom: 0;
              }
            }
            .radio-row {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .card-type-no {
                display: flex;
                align-items: center;
                letter-spacing: 0.1em;
                width: 41%;
                @include bp(sm-max) {
                  width: 100%;
                  margin-bottom: 0.2rem;
                }
                .card-type {
                  line-height: 0;
                  margin-right: 2rem;
                }
              }
              .card-holder-name {
                width: 41%;
                @include bp(sm-max) {
                  width: calc(100% - 8rem);
                }
              }
              .card-expiry {
                width: 10rem;
                text-align: right;
                @include bp(lg-max) {
                  width: 8rem;
                }
              }
            }
          }
        }
      }
      .transfer-detail-wrapper {
        margin-bottom: 2.4rem;
        .country-field {
          position: relative;
          z-index: 1;
          margin-bottom: -0.1rem;          
        }
      }
      .payment-status-wrapper {
        .radio-wrapper {
          .box-radio {
            height: 100%;
            @include bp(sm-max) {
              height: auto;
            }
          }
        }
        .upload-proof-wrapper {
          margin-top: 6rem;
          @include bp(lg-max) {
            margin-top: 5rem;
          }
          .internal-section-title {
            margin-bottom: 0;
            margin-top: 0;
          }
          > * {
            margin-top: 4rem;
            @include bp(lg-max) {
              margin-top: 2.5rem;
            }
          }
          .file-uploading-wrapper {
            min-width: 42rem;
            @include bp(sm-max) {
              min-width: inherit;
            }
          }
        }
      }
      .transfer-detail-outer {
        padding: 2.2rem;
        border: 0.1rem solid $grey8;
        border-radius: 0 0 0.4rem 0.4rem;
        font-size: 1.6rem;
        line-height: 1.5;
        background: $white;
      }
      .transfer-detail-block {
        margin-bottom: 2.3rem;
        padding-bottom: 2.3rem;
        border-bottom: 0.1rem solid $grey2;
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
        .transfer-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1.2rem;
          h6 {
            margin: 0;
          }
          .be-copy {
            line-height: 0;
            font-size: 2.5rem;
            color: $trueGrey;
            cursor: pointer;
            &:hover {
              color: $purple1;
            }
          }
        }
        .transfer-outer {
          .transfer-row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 0.4rem;
            @include bp(sm-max) {
              margin-bottom: 1rem;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .title {
              color: $trueGrey;
              width: 25.2%;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              @include bp(lg-max) {
                width: 30%;
              }
              @include bp(sm-max) {
                width: 100%;
              }
              .be-tooltip {
                line-height: 0;
                color: $purple1;
                margin-left: 0.8rem;
                cursor: pointer;
              }
            }
            .value {
              width: 74.8%;
              @include bp(lg-max) {
                width: 70%;
              }
              @include bp(sm-max) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .plan-selection-row {
      margin: 0 -1.2rem -2.4rem;
      padding-top: 2.1rem;
      @include bp(lg-max) {
        padding-top: 0;
      }
      .plan-selection-col {
        padding: 0 1.2rem;
        .sidenav-submenu-fixed & {
          @include bp(xlg-max) {
            width: 50%;
          }
        }
        @include bp(xlg-max) {
          width: 50%;
        }
        @include bp(xs-max) {
          width: 100%;
        }
      }
    }
    .billing-form-wrapper {
      margin-bottom: 1.8rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .billing-view-wrapper {
      background: $white;
      border: 0.1rem solid $grey10;
      @extend .be-radius;
      padding: 4rem 3.2rem 3.2rem;
      margin-bottom: 2.8rem;
      @include bp(lg-max) {
        padding: 3rem 2rem 2rem;
      }
      @include bp(sm-max) {
        padding: 2rem;
      }
      &:last-child {
        margin: 0;
      }
      .view-flexbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .left-info {
          width: calc(100% - 13rem);
          padding-top: 0.8rem;
          @include bp(sm-max) {
            width: 100%;
            padding-top: 0;
          }
        }
        .right-icon {
          width: 13rem;
          padding-left: 0.5rem;
          @include bp(sm-max) {
            display: none;
          }
        }
        h3 {
          font-weight: 300;
          margin-bottom: 0.8rem;
        }
        .info-list {
          @extend .no-bullets;
          font-size: 1.6rem;
          @include line-height(16, 28);
          li {
            margin-bottom: 0.8rem;
            &:last-child {
              margin-bottom: 0;
            }
            strong {
              font-weight: 500;
            }
          }
        }
      }
      .view-btn {
        margin-top: 0.8rem;
        display: flex;
        justify-content: flex-end;
        @include bp(sm-max) {
          text-align: left;
          margin-top: 2.5rem;
        }
        .MuiButton-root {
          min-width: 20.7rem;
          font-size: 1.4rem;
          @include bp(sm-max) {
            min-width: inherit;
            width: 100%;
          }
        }
      }
    }

    &::-webkit-scrollbar-thumb {
      	background: #f8f9fa;
      	border-radius: 0.4rem;
      	opacity: 0;
      }
      &::-webkit-scrollbar-thumb:hover {
      	opacity: 1;
      	background: #AFB2C0;
      }
      &:hover {
      	&::-webkit-scrollbar-thumb {
      		background: #AFB2C0;
      	}
  }
}
  .checkout-summary {
    width: 33.5rem;
    border-left: 0.1rem solid $grey2;
    // min-height: calc(100vh - 10rem);
    padding: 3rem 3rem 1.6rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 9rem;
    bottom: 0;
    overflow: auto;
    @include custom-scroll;
    @include bp(lg-max) {
      width: 30rem;
      top: 7.5rem;
      padding: 2rem 2rem 1.6rem 2rem;
    }
    @include bp(smd-max) {
      position: static;
      width: 100%;
      height: auto;
      padding: 3rem 3rem 1.6rem;
      border-top: 0.1rem solid $grey3;
    }
    @include bp(sm-max) {
      padding: 2rem 2rem 1.6rem;
    }
    .checkout-content-outer {
      width: 100%;
    }
    .checkout-plan-title {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 1.2rem;
      h4 {
        margin: 0;
        width: 100%;
        padding-right: 8.5rem;
      }
      .plan-icon {
        display: inline-flex;
        width: 8rem;
        position: absolute;
        right: 0;
        top: -0.8rem;
      }
    }
    .checkout-costing {
      border-top: 0.1rem solid $grey6;
      margin-top: 1.5rem;
      padding-top: 2rem;
      @include bp(sm-max) {
        margin-top: 3rem;
        padding-top: 3rem;
      }
      .cost-row {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        @include line-height(14, 20);
        margin-bottom: 1rem;
        color: $black1;
        .cost {
          white-space: nowrap;
        }
        &.total-row {
          padding-top: 1.4rem;
          font-size: 1.6rem;
          @include line-height(16, 24);
          color: $black;
          font-weight: 500;
          margin-top: 2rem;
          border-top: 1px solid $grey6;
        }
      }
    }
    .checkout-buttons {
      padding-top: 5rem;
      margin: auto -1.4rem 0;
      width: auto;
      @include bp(smd-max) {
        display: flex;
        justify-content: space-between;
      }
      @include bp(xs-max) {
        flex-direction: column;
        margin: auto -1rem 0;
      }
      &.has-checkbox {
        @include bp(sm-max) {
          flex-wrap: wrap;
        }
        .MuiButton-root {
          @include bp(sm-max) {
            width: 100%;
          }
        }
      }
      .checkbox-control {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 2.8rem;
        @include bp(smd-max) {
          width: calc(50% - 0.8rem);
          margin-bottom: 0;
        }
        @include bp(xs-max) {
          width: 100%;
          margin-bottom: 1.6rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .MuiButton-root {
        width: 100%;
        margin-bottom: 1.6rem;
        @include bp(smd-max) {
          width: calc(50% - 0.8rem);
          margin-bottom: 0;
        }
        @include bp(xs-max) {
          width: 100%;
          margin-bottom: 1.6rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.MuiCheckbox-root {
          width: auto;
          margin: 0;
        }
        &.grey-btn {
          background-color: $lightPurple;
        }
      }
    }
  }
}

.page-container {
  padding: 0 2.5rem;
  .plan-checkout-block {
    border: 1px solid $purple3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 0 2.4rem ;
    .left-plan {
      width: 31%;
      border-right: 1px solid $purple3;
      @include bp(lg-max) {
        width: 100%;
      }
      .plan-name-wrapper {
        padding: 2.5rem 2.3rem 2.4rem;
        background-color: $lightPurple;
        position: relative;
        border-bottom: 1px solid $purple3;

        .plan-icon {
          display: flex;
          margin-bottom: 1.6rem;
          align-items: center;
          width: 12.0rem;
          @include bp(sm-max) {
            width: 9rem;
          }
        }

        h3 {
          color: $purple1;
          font-size: 2.8rem;
          font-weight: 500;
          line-height: 3.3rem;
          margin-bottom: 1rem;
          @include bp(sm-max) {
            font-size: 2.4rem;
          }
        }

        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $black1;
        }
      }

      h4 {
        font-size: 1.8rem;
        margin: 0;
      }
      .plan-inner-content {
        padding: 2.5rem;
        ul {
          list-style: none;
          margin-top: 1.8rem;
          padding: 0;
          li {
            background: url(../../assets/images/check-icon.svg);
            background-repeat: no-repeat;
            background-size: 24px;
            padding: 0 0 1.2rem 3rem;
            color: $black1;
            font-size: 1.4rem;
          }
        }
        h2 {
          margin-bottom: 0;
        }
      }
      .plan-btn {
        border: 1px solid rgba(179, 136, 255, 0.3);
        border-radius: 0.8rem;
        width: 100%;
        background-color: $white;
        .be-right-arrow-thin {
          transform: rotate(180deg);
          font-size: 2.2rem;
          margin: -0.7rem 0.6rem 0 0;
        }
      }
    }
  }
  .plan-checkout-table {
    width: 69%;
    @include bp(lg-max) {
      width: 100%;
    }
    .table {
      width: 100%;
    }
    tr {
      td {
        font-size: 1.4rem;
        font-weight: 400;
        color: $black1;
        text-align: center;
        border-bottom: 1px solid $grey18;
        width: 65%;
        padding: 0.8rem 0;
        @include bp(sm-max) {
          display: block;
          width: 100%;
          padding: 0.8rem 1rem;
        }
        span {
          color: $grey11;
        }
        p {
          padding-top: 5px;
          max-width: 403px;
          margin: 0 auto;
          line-height: 2rem;
          padding-right: 1rem;
        }
        .check-icon {
          background-color: $green1;
          border-radius: 1rem;
          width: 2.8rem;
          height: 2rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &.yellow {
            background-color: $amber;
          }
        }
      }
      th {
        font-size: 1.6rem;
        font-weight: 500;
        color: $black1;
        border-bottom: 1px solid $grey18;
        text-align: left;
        padding-left: 3%;
        padding-bottom: 2.4rem;
        padding-top: 2.4rem;
        width: 40%;
        @include bp(sm-max) {
          display: block;
          width: 100%;
          text-align: center;
          padding-right: 3%;
          padding-bottom: 1.4rem;
          padding-top: 1.4rem;
        }
        em {
          font-size: 1.5rem;
          color: $grey4;
          position: relative;
          top: 3px;
          padding-left: 5px;
        }
      }
      &:last-child {
        td,
        th {
          border: 0;
        }
      }
    }
  }
}
