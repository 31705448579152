@import "../../../assets/scss/goals";

// Business goals styles
.empty-goal-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translate(40%, 40%);
  font-size: 14px;
}
