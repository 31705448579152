@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';

.page-filters-wrapper {
  .right-filter {
    .MuiButtonBase-root {
      width: 3.6rem;
      min-width: 3.6rem;
    }

    .MuiButton-root {
      &.purple-btn {
        min-width: 15.2rem;
        letter-spacing: 0.014rem;
        color: $black2;
      }

      &.is-icon-btn::before {
        left: 0;
      }
    }
  }
}

.form-container {
  padding: 2.4rem;
  border-radius: 0.8rem;
  background: $grey1;
  margin-bottom: 3.2rem;

  .has-add-email {
    position: relative;

    .add-email-wrapper {
      position: absolute;
      top: 6rem;
      left: 0;
      right: 0;
      border: 0.1rem solid $grey8;
      border-radius: 0 0 0.4rem 0.4rem;
      z-index: 1;
      background: $white;

      .add-email-box {
        cursor: pointer;
        padding: 1.6rem;
        border-left: 0.4rem solid $purple1;
        background: $grey7;
        font-weight: 400;
        font-size: 1.4rem;
        @include line-height(14, 20);
        letter-spacing: 0.01em;
        overflow: visible;
        text-overflow: inherit;
        white-space: inherit;
        strong {
          word-break: break-all;
        }
      }
    }
  }
}

//owner-email-dropdown
.MuiPopper-root.MuiAutocomplete-popper {
  .owner-email-dropdown {
    display: flex;
    align-items: center;
    width: 100%;

    .owner-initials {
      width: 3.2rem;
      min-width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      border: 1px solid $lightPurple;
      background: $lightPurple;
      margin-right: 1rem;
      font-size: 1rem;
      font-weight: bold;
      color: $purple2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
    .right-side {
      display: block;
      //width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
      @extend .truncated-text;
      .owner-name {
        color: $black2;
        font-weight: bold;
        font-size: 1.4rem;
        margin-right: 0.8rem;
      }
      .owner-email {
        color: $trueGrey;
        font-size: 1.4rem;
      }
      @include bp(sm-max) {
        display: block;
        width: calc(100% - 4.2rem);
        flex-direction: column;
        align-items: flex-start;
        @extend .truncated-text;
        .owner-name {
          display: block;
        }
        .owner-email {
          display: block;
        }
      }
    }
  }
}

.modal-btn-block {
  .btn-save {
    width: 100%;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
}

.spaces-list-container {
  background: $white;
  padding: 0;
  margin-top: 1.6rem;
  max-height: 40rem;
  overflow: auto;
  @include custom-scroll;

  ul {
    margin: 0 0.4rem 0 0;
    padding: 0;

    li {
      padding: 1.6rem 0;
      border-bottom: 1px solid $grey8;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border: 0;
      }

      .description {
        font-size: 1.6rem;

        .email {
          margin-top: 0.3rem;
          font-size: 1.4rem;
          color: $trueGrey;
        }
      }

      .icon-delete {
        cursor: pointer;
        height: 2rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

.table-wrapper {
  .multiple-user-icon {
    border-radius: 0.4rem;
    padding: 0.4rem;
    width: 2.4rem;
    opacity: 0.9;
    .icon {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .lock-icon,
  .archive-icon {
    padding: 0 0 0 0.4rem;
    width: 2.4rem;
    cursor: pointer;
    display: flex;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      margin-left: 8px;
    }
    em {
      margin-left: 8px;
      font-size: 16px;
    }
  }
  .MuiTable-root {
    .icon-archive {
      display: inline-block;
      margin-top: 0.4rem;
      margin-right: 1.4rem;
      cursor: pointer;
    }
    .be-down-arrow-1 {
      margin: 0 0 0 0.8rem;
      font-size: 12px;
      position: relative;
      top: 0.2rem;
    }
    .be-left-arrow {
      display: inline-block;
      &.move-down {
        transform: rotate(270deg);
      }
      &.move-up {
        transform: rotate(90deg);
      }
    }
    .MuiTableRow-root {
      &.disabled {
        .bc-count {
          color: $grey5;
        }
        .lock-icon,
        .archive-icon {
          em {
            color: $purple2;
          }
        }
        .user-name {
          color: $purple2 !important;
        }
        .user-link {
          .multiple-user-icon {
            opacity: 0.5;
          }
        }
        .MuiTableCell-body {
          color: $purple2 !important;
        }
        .initials {
          background: #c7c9ce !important;
          color: #8f8f93;
          opacity: 0.7;
          font-weight: 400;
          &.more {
            background: $grey2 !important;
          }
        }
        .icon-archive {
          &::before {
            color: $black1;
          }
        }
      }
    }
  }
  .initials-wrap {
    display: inline-flex;
    align-items: center;
  }
  .initials {
    height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    border-radius: 50%;
    background: $purple2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    font-size: 1rem;
    color: $black2;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    margin-right: 0.8rem;
    img {
      margin: 0 !important;
      top: 0 !important;
    }
    // + .initials {
    // 	margin-left: -0.5rem;
    // }
  }

  .progress-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    cursor: pointer;

    .progress-bar {
      height: 10px;
    }

    &:hover {
      .credits {
        display: block;
      }
    }
  }

  .three-dots {
    cursor: pointer;
    margin: 0 1.2rem 0 0;

    .be-vertical-dots {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    min-width: 20rem;
    border-radius: 8px;
    box-shadow:
      0px 1px 4px 0px rgba(0, 0, 0, 0.08),
      0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  }
}

.credits {
  .date-row {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;

    span:first-child {
      display: flex;
      align-items: center;

      em {
        margin-right: 4px;
      }
    }

    .icon-blue,
    .icon-grey {
      background: #6200ea;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 100%;
    }

    .icon-grey {
      background: $grey8;
    }

    .price {
      font-weight: bold;
    }
  }
}

.MuiPaper-root {
  &.MuiMenu-paper {
    &.MuiPaper-elevation {
      &.MuiPaper-rounded {
        min-width: 11.1rem;
        border-radius: 0.8rem;
        box-shadow:
          0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.02),
          0 0.4rem 0.2rem 0 rgba(0, 0, 0, 0.01);
      }
    }
  }

  .toggle-menu-item-row {
    .icon {
      margin-right: 1.2rem;
      height: 1.8rem;
    }
    img {
      height: 1.5rem;
      margin-right: 1.2rem;
    }
  }
}

.make-private-label {
  display: inline-flex;
  align-items: center;
  .be-info {
    line-height: 0;
    display: inline-flex;
    margin-left: 5px;
    font-size: 1.4rem;
  }
}

// Space Idea board
.idea-board-wrapper,
.whiteboard-wrapper {
  .main-page-header {
    padding: 0;
    justify-content: space-between;
    //background: $grey7;
    background: transparent;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include bp(md-max) {
      align-items: flex-start;
    }

    .left-page-header {
      display: flex;
      align-items: center;
      .MuiButtonBase-root {
        margin: 0 1.6rem 0 0;
        &.btn-back {
          background: $white;
          width: 3.6rem;
          height: 3.6rem;
          min-width: 3.6rem;
          border: 1px solid rgba(179, 136, 255, 0.3);
          @include bp(md-max) {
            margin-top: 1rem;
          }
          .btn-icon {
            font-size: 1.6rem;
            margin: 0;
          }
        }
      }
      .dropdown-wrap {
        padding-left: 1.7rem;
        .MuiButtonBase-root {
          min-width: 10rem;
          width: 10rem;
          height: 3.6rem;
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 500;
          color: $black1;
          border-radius: 0.8rem;
          em {
            margin: 0 0 0 1.3rem;
            line-height: 1;
          }
        }
      }
    }
    .right-page-header {
      .compnay-logo {
        padding-right: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 3.2rem;
        width: 10rem;
        @include bp(sm-max) {
          width: 3.5rem;
        }

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }

  // Ideaboard iframe page wrapper
  .iframe-page-head {
    max-height: 6.5rem;
    .main-page-header {
      padding: 1.2rem 1.4rem 0.8rem 1.4rem;
      .left-page-header {
        align-items: normal;
      }
      .right-page-header {
        .compnay-logo {
          justify-content: flex-end;
        }
      }
    }
    .page-breacrumb {
      @include bp(md-max) {
        margin-top: 2rem;
      }
    }
  }

  .separator {
    border: 0;
    border-bottom: 1px solid $grey2;
    display: block;
    margin: 0 -4.8rem;
    height: 1px;
  }
  .page-filters-wrapper.secondary-header {
    // margin-bottom: 0;
    // padding-bottom: 2.4rem;

    .right-filter {
      .MuiButton-root.purple-btn {
        &.btn-start-idea {
          min-width: 20.8rem;
          height: 4.8rem;
        }
      }
      .btn-add-whiteboard {
        margin: 0;
        border: 0.1rem solid rgba(179, 136, 255, 0.3);
        background-color: $white;
        width: 4.8rem !important;
        height: 4.8rem !important;
        border-radius: 0.8rem;
        margin: 0 0.8rem;
        padding: 12px !important;
        &:hover,
        &:active,
        &:visited {
          background-color: $lightPurple !important;
          border-color: $purple2 !important;
        }
        img {
          width: 2rem;
          height: auto;
        }
        &.is-icon-btn {
          width: 3.2rem !important;
          height: 3.2rem !important;
          border: 0;
          padding: 0;
          margin: 0 0 0 3.2rem;
          img {
            width: 3.2rem;
            height: auto;
          }
        }
      }
    }
  }
  .inner-section-wrap {
    padding: 0rem 0 2.4rem;
    .left-filter {
      .count {
        display: flex;
        align-items: center;
        color: $black1;
        font-size: 2rem;
        font-weight: 500;
        @include line-height(20, 32);
        img {
          height: 2rem;
          width: auto;
          margin: 0 0.8rem 0 0;
        }
      }
    }
    .page-filters-wrapper {
      margin-bottom: 2.4rem;
      .right-filter {
        margin-right: 0;
        .form-group.sort-by {
          margin-right: 2.2rem;
          .dropdown-text {
            margin-right: 0.4rem;
          }
        }
      }
    }
    .tab-label-wrap {
      .MuiBox-root {
        .MuiTabs-root {
          min-height: 3.6rem;
          .MuiTabs-flexContainer {
            background: $grey13;
            border-radius: 0.8rem;
            .MuiButtonBase-root {
              min-width: 4.2rem;
              min-height: 3.6rem;
              font-size: 1.4rem;
              font-weight: 400;
              padding: 0.6rem 1.2rem;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              color: $black1;
              text-transform: capitalize;
              &[aria-selected='true'] {
                background: $lightPurple;
                border-radius: 0.8rem;
                color: $purple1;
                font-weight: 500;
              }
              //.MuiTouchRipple-root {}
              &:hover {
                background: $lightPurple;
                border-radius: 0.8rem;
                color: $purple1;
              }
            }
          }
        }
        .MuiTabs-indicator {
          display: none;
        }
      }
    }
    // Tab content section
    .MuiTabPanel-root {
      padding: 0;
      .row-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2.5rem 1.5rem 3.2rem;
        border-radius: 0 0 0 2.4rem;
        border: 1px solid $grey2;
        background: $white;
        min-height: 7.6rem;
        margin: 0 0 1.6rem;
        @include transition(all ease-in 0.2s);
        @include bp(sm-max) {
          flex-wrap: wrap;
          padding: 1.5rem;
        }
        .left-content {
          width: 35%;
          @include bp(sm-max) {
            width: 100%;
          }
          .duration {
            font-size: 1.4rem;
            color: $black1;
            font-weight: 400;
            width: 100%;
            span {
              color: $grey11;
            }
          }
        }
        .left-content,
        .right-content {
          display: flex;
          align-items: center;
          color: $black1;
          font-size: 1.6rem;
          font-weight: 400;
          @include line-height(16, 24);
          .label {
            font-weight: 500;
          }
          .icon-wrap {
            img,
            .icon {
              width: 2.5rem;
              height: auto;
              margin-right: 2rem;
              position: relative;
              top: 0.3rem;
              left: 0;
              &::before {
                font-size: 2.5rem;
              }
            }
            .icon {
              top: 0.2rem;
            }
          }
        }
        .right-content {
          width: 40%;
          justify-content: flex-end;
          @include bp(sm-max) {
            width: 100%;
            margin-top: 1rem;
          }
          > span {
            padding: 0 3.2rem 0 0;
            &.auth-name {
              max-width: 20rem;
              padding-right: 2.4rem;
              text-align: left;
              width: 65%;
              @extend .truncated-text;
            }
          }
          .date-time {
            padding-right: 2.4rem;
            width: 35%;
            @include bp(lg-max) {
              width: 60%;
            }
          }
          span {
            font-size: 1.4rem;
          }
          .three-dots {
            width: 14.8rem;
            justify-content: end;
            display: flex;
            @include bp(sm-max) {
              display: none;
            }
            .be-vertical-dots {
              display: flex;
              padding: 1.4rem;
            }
          }
        }
        .center-content {
          align-items: center;
          display: flex;
          .initials {
            span {
              height: 1.6rem;
              line-height: 1;
              display: inline-flex;
              align-items: center;
            }
          }
          .grey {
            background-color: $grey6 !important;
          }
        }
        &:hover {
          cursor: pointer;
          border-color: $purple;
          background-color: $lightPurple1;
          .three-dots {
            .icon {
              background-color: $white;
              border-radius: 0.8rem;
            }
          }
        }
      }
    }
  }
  .MuiOutlinedInput-root.borderless-dropdown .MuiSelect-select {
    padding-right: 2.4rem !important;
  }
  .search-whiteboard {
    width: 30.4rem;
  }
  .MuiTableContainer-root {
    .MuiTable-root {
      .MuiTableRow-root {
        &:nth-child(odd) {
          background-color: $white;
        }
        .MuiTableCell-body {
          padding: 1.6rem;
          height: 6.4rem;
          border: 0;
          font-size: 1.4rem;
          letter-spacing: 0.014rem;
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            width: 20%;
          }
          &:first-child {
            padding-left: 1.6rem;
            strong {
              font-size: 1.6rem;
              font-weight: 500;
            }
          }
          &:last-child {
            width: 40%;
            text-align: right;
          }
          .three-dots {
            margin: 0 1.6rem 0 0;
            display: inline-flex;
          }
        }
        &:last-child td {
          border: 0;
        }
      }
    }
  }
  .no-ideas-yet {
    p {
      margin: 0 0 1.6rem 0;
    }
    .btn-new-board {
      padding: 0.8rem 1.6rem;
      height: 4.8rem !important;
      img {
        margin-right: 0.8rem;
      }
    }
  }
  .has-initials {
    .initials {
      margin-left: -0.5rem;
      margin-right: 0;  
    }
  }
}

.whiteboard-wrapper {
  min-height: 5.6rem;
  background: $grey7;
  .iframe-page-head {
    .main-page-header {
      .left-page-header {
        .page-breacrumb {
          margin-bottom: 0;
          @include bp(md-max) {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
}

.three-dot-menu {
  .MuiPaper-root {
    // max-width: 9.3rem;
    min-width: 9.3rem !important;
    .MuiList-root {
      padding: 0 !important;
      .MuiButtonBase-root {
        padding: 1.2rem 1.6rem !important;
        background: $white !important;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        font-size: 1.2rem;
        font-weight: 400;
        @include line-height(12, 16);
        letter-spacing: 0.012rem;
        img {
          margin-right: 0.8rem;
        }
      }
      &:hover {
        .MuiButtonBase-root {
          background: $lightPurple !important;
        }
      }
    }
  }
}

.delete-whiteboard-modal {
  padding: 4rem !important;
  .modal-body {
    .modal-title {
      margin-bottom: 1.6rem;
    }
  }
  .modal-btn-block {
    margin-top: 4rem;
  }
}

.date-tooltip {
  position: relative;
  left: -67px !important;
  .MuiTooltip-tooltip {
    font-size: 1.4rem !important;
    color: $dark-grey !important;
    padding: 1.5rem 2rem !important;
    text-align: center;
  }
}

.no-whiteboards {
  padding: 6.2rem 1.5rem !important;
  h4 {
    font-size: 2rem !important;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1.6rem !important;
  }
  .xs-btn {
    height: 4.8rem !important;
    font-weight: 500;
    color: $black1;
  }
  .right-filter {
    display: flex;
    justify-content: center;
    .btn-add-whiteboard {
      margin: 0;
      border: 0.1rem solid rgba(179, 136, 255, 0.3);
      background-color: $white;
      width: 4.8rem !important;
      height: 4.8rem !important;
      border-radius: 0.8rem;
      margin: 0 0.8rem;
      padding: 12px !important;
      &:hover,
      &:active,
      &:visited {
        background-color: $lightPurple !important;
        border-color: $purple2 !important;
      }
    }
  }
}

.center-content {
  .inner-container {
    background: $white;
    border-radius: 0.4rem;
    box-shadow: 0 0.6rem 1.6rem 0 rgba($black, 0.15);
    padding: 0.8rem 1.6rem;
    color: $black1;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.012rem;
    font-weight: 400;
    max-width: 32.4rem;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 16rem;
    display: none;
    top: 35px;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      top: -0.5rem;
      box-shadow: 0 0 1rem 0 rgba($black, 0.15);
      height: 1rem;
      width: 1rem;
      background: $white;
      z-index: -1;
      display: block;
      transition: none;
    }

    &:after {
      content: '';
      content: '';
      top: -0.3rem;
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $white;
      transform: rotate(314deg);
      width: 1.6rem;
      height: 1.6rem;
      left: 45%;
      z-index: -1;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    @include custom-scroll;
    max-height: 22rem;
    li {
      display: flex;
      font-size: 1.2rem;
      padding: 0.8rem 1.6rem;
      text-transform: none;
      align-items: center;
      border-bottom: 1px solid $grey6;
      &:last-child {
        border-bottom: 0;
      }
      em {
        margin-right: 0.8rem;
        font-style: normal;
        img {
          border-radius: 100%;
        }
      }
    }
  }
  .toolip-hover {
    position: relative;
    &:hover {
      .inner-container {
        display: block;
      }
    }
  }
}

.download-icon {
  font-size: 1.6rem;
  color: $black1;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
}
