@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';

.company-roles-permissions-page {
  .page-content {
    padding-top: 2.4rem;
    @include bp(sm-max) {
      padding-bottom: 2.4rem;
    }
  }
  .search-result-wrapper {
    h5 {
      color: $trueGrey;
    }
    .no-search-results {
      padding: 14.8rem 0;
      @include bp(lg-max) {
        padding: 12rem 0;
      }
      @include bp(sm-max) {
        padding: 8rem 0;
      }
    }
  }
  .accordion-heading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    // min-height: 6.4rem;
    @include bp(lg-max) {
      padding: 0;
    }
    @include bp(sm-max) {
      min-height: inherit;
    }
    .left-heading {
      display: flex;
      align-items: center;
      width: 100%;
      @include bp(sm-max) {
        width: 100%;
      }
      .icon {
        line-height: 0;
        height: 2.4rem;
        width: 2.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        @extend .transition;
        transform: rotate(90deg);
      }
      h5 {
        margin: 0 0 0.5rem;
        width: calc(100% - 2.4rem);
      }
    }
    .right-btns {
      display: none;
      justify-content: flex-end;
      width: 35rem;
      max-width: 100%;
      position: absolute;
      top: 3.5rem;
      @include bp(sm-max) {
        position: static;
      }
      @include bp(xsm-max) {
        width: 100%;
        justify-content: flex-start;
        margin-top: 2rem;
      }
      @include bp(xs-max) {
        flex-direction: column;
      }
      .MuiButton-root {
        padding: 0 1.6rem;
        margin-right: 1.6rem;
        @include bp(xsm-max) {
          margin-right: 0;
          margin-bottom: 1.4rem;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .roles-table {
    margin-bottom: 3rem;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
    h6 {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.014rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
    }
    .custom-table {
      &.secondary {
        td {
          &.role-title {
            white-space: nowrap;
            @include bp(sm-max) {
              min-width: 22.5rem;
            }
          }
          &.text-center {
            .MuiFormControlLabel-root {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .right-filter {
    position: relative;
    top: 0;
    @include bp(sm-max) {
      top: 0;
    }
  }
}
