@import './bootstrap-grid.min';
@import './bootstrap-utilities';
@import './swiper-bundle.min';
@import 'react-phone-input-2/lib/style.css';

@import './fonts';
@import './custom-fonts';
@import './colors';
@import './mixins';
@import './extended-classes';
@import './break-points';
@import './typography';
@import './form-elements';
@import './dialog';
@import './table';
@import './generic-components';
@import './onboarding';
@import './company';
@import './tab-page-section';
@import './tag-result';
@import './integrations';
@import './user-profile-tooltip';
@import './card-template';
@import './login';
@import './header';
@import './page-top-navigation';
@import './page-unauthorise-404';
@import './common-components';
