//Break points
@mixin bp($point) {
  $xxs-max: '(max-width: 374px)';
  $xsm-max: '(max-width: 480px)';
  $xs-max: '(max-width: 575px)';
  $sm-max: '(max-width: 767px)';
  $smd-max: '(max-width: 991px)';
  $md-max: '(max-width: 1024px)';
  $lg-max: '(max-width: 1199px)';
  $xlg-max: '(max-width: 1366px)';
  $xll-max: '(max-width: 1440px)';
  $xl-max: '(max-width: 1600px)';
  $xxl-max: '(max-width: 2000px)';
  $lg-min: '(min-width: 1200px)';

  @if $point == xxs-max {
    @media #{$xxs-max} {
      @content;
    }
  }
  @if $point == xsm-max {
    @media #{$xsm-max} {
      @content;
    }
  } @else if $point == xs-max {
    @media #{$xs-max} {
      @content;
    }
  } @else if $point == sm-max {
    @media #{$sm-max} {
      @content;
    }
  } @else if $point == smd-max {
    @media #{$smd-max} {
      @content;
    }
  } @else if $point == md-max {
    @media #{$md-max} {
      @content;
    }
  } @else if $point == lg-max {
    @media #{$lg-max} {
      @content;
    }
  } @else if $point == xlg-max {
    @media #{$xlg-max} {
      @content;
    }
  } @else if $point == xll-max {
    @media #{$xll-max} {
      @content;
    }
  } @else if $point == xl-max {
    @media #{$xl-max} {
      @content;
    }
  } @else if $point == xxl-max {
    @media #{$xxl-max} {
      @content;
    }
  } @else if $point == lg-min {
    @media #{$lg-min} {
      @content;
    }
  }
}
