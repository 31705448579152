.natasha-welcome {
  min-height: 100vh;
  display: flex;
  align-items: center;
  &.has-header {
    align-items: flex-start;
    .inner-content {
      padding: 0;
    }
    .welcome-content {
      min-height: calc(100vh - 15.2rem);
    }
  }
  &.profile-setup-page {
    padding: 10rem 0;
    position: relative;
    @include bp(lg-max) {
      padding: 10rem 0 5rem;
    }
    .logo-space {
      position: fixed;
      top: 3.2rem;
      right: 5.5rem;
      border: 0.1rem dashed $grey;
      border-radius: 0.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.01em;
      color: $grey11;
      padding: 0.9rem 1.2rem;
      z-index: 1;
      @include bp(lg-max) {
        right: 2.5rem;
        top: 2rem;
      }
      @include bp(sm-max) {
        position: absolute;
      }
    }
    .welcome-content {
      .photo-upload-wrapper {
        position: relative;
        margin: 0 auto;
        width: 30rem;
        z-index: 0;
        max-width: 100%;
        .welcome-logo {
          position: absolute;
          margin: 0;
          bottom: -13.6rem;
          left: -14rem;
          z-index: -1;
          @include bp(sm-max) {
            bottom: auto;
            top: -26rem;
            left: -2rem;
          }
          &.fixed {
            position: fixed;
            right: 12.3rem;
            top: 6.5rem;
            left: auto;
            bottom: auto;
            @include bp(lg-max) {
              right: 10rem;
              top: 4.5rem;
            }
            @include bp(sm-max) {
              position: absolute;
              bottom: auto;
              top: -26rem;
              left: -2rem;
            }
          }
        }
        .btn-wrap {
          margin-top: 9.7rem;
        }
      }
      .profile-form {
        position: relative;
        z-index: 0;
        &.min-height {
          min-height: 30rem;
          @include bp(lg-max) {
            min-height: 20rem;
          }
          @include bp(sm-max) {
            min-height: 15rem;
          }
          .welcome-logo {
            bottom: auto;
            top: 5rem;
          }
        }
        .welcome-logo {
          position: absolute;
          margin: 0;
          bottom: -12.2rem;
          left: -13rem;
          z-index: -1;
          @include bp(lg-max) {
            bottom: -7rem;
            left: -9rem;
          }
          @include bp(sm-max) {
            bottom: auto;
            top: -26rem;
            left: -2rem;
          }
        }
      }
      p {
        letter-spacing: -0.02rem;
      }
      .btn-wrap {
        margin-top: 8.1rem;
        @include bp(lg-max) {
          margin-top: 5rem;
        }
      }
    }
  }
  &.project-selection-page {
    .container.xs {
      max-width: 76rem;
    }
    .welcome-content {
      padding: 14rem 0;
      position: relative;
      @include bp(lg-max) {
        min-height: calc(100vh - 3rem);
      }
      @include bp(sm-max) {
        padding: 6rem 0 8rem;
      }
      .skip-link {
        position: absolute;
        right: 4.8rem;
        bottom: 4.8rem;
      }
    }
    .welcome-logo {
      margin: 0;
      padding-top: 2.7rem;
      @include bp(sm-max) {
        padding-top: 0;
      }
    }
    .project-selection {
      width: calc(100% - 17rem);
      @include bp(sm-max) {
        width: 100%;
      }
      .radio-wrapper {
        margin-top: 3rem;
      }
    }
  }
  &.plan-recommended-page {
    .welcome-content {
      @include bp(lg-max) {
        padding: 8rem 0;
      }
      @include bp(smd-max) {
        padding: 6rem 0;
      }
      .container.xs {
        max-width: 84.3rem;
      }
      .flex-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
      .welcome-logo {
        margin: -5rem 0 0;
      }
      .recommend-plan-wrapper {
        width: calc(100% - 17rem);
        padding: 1.9rem 0 1.9rem 1rem;
        @include bp(lg-max) {
          padding-left: 0;
        }
        @include bp(sm-max) {
          width: 100%;
          padding-bottom: 0;
        }
        .h1 {
          padding-left: 2.5rem;
          margin-bottom: 5.5rem;
          @include bp(smd-max) {
            margin-bottom: 3rem;
          }
          @include bp(sm-max) {
            padding-left: 0;
          }
        }
        .recommended-btn-wrap {
          display: flex;
          justify-content: center;
          margin-top: 5.6rem;
          @include bp(xsm-max) {
            flex-direction: column;
          }
          .MuiButton-root {
            margin-right: 1.6rem;
            min-width: 18rem;
            @include bp(xsm-max) {
              margin-right: 0;
              margin-bottom: 1rem;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .inner-content {
    padding: 4.8rem 0;
    width: 100%;
  }
  .welcome-logo {
    display: inline-flex;
    width: 17rem;
    margin-left: -14.8rem;
    @include bp(lg-max) {
      width: 11rem;
      margin-left: -5rem;
    }
    @include bp(sm-max) {
      width: 11rem;
      margin-left: -0.8rem;
    }
  }
  .welcome-content {
    .welcome-title {
      margin-bottom: 5.5rem;
    }
    p {
      color: $trueGrey;
      font-size: 1.8rem;
      line-height: 2.5rem;
      @include bp(sm-max) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
  .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20rem;
    @include bp(lg-max) {
      margin-top: 15rem;
    }
    @include bp(sm-max) {
      margin-top: 12rem;
    }
    @include bp(xsm-max) {
      flex-direction: column;
    }
    .MuiButton-root {
      min-width: 18rem;
      margin-right: 1em;
      @include bp(xsm-max) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.member-onboarding-nav {
  @extend .no-bullets;
  position: fixed;
  right: 5.5rem;
  top: 50%;
  @include translateY(-50%);
  @include bp(lg-max) {
    right: 2.5rem;
  }
  @include bp(sm-max) {
    position: absolute;
    left: 2rem;
    right: 2rem;
    top: 1rem;
    transform: none;
    display: flex;
    justify-content: space-between;
  }
  &.has-five-steps {
    li {
      height: 8.6rem;
      @include bp(sm-max) {
        width: calc(20% - 0.6rem);
        height: 0.5rem;
      }
    }
  }
  li {
    margin-bottom: 0.8rem;
    background: #ecedf0;
    border-radius: 1.4rem;
    width: 1rem;
    height: 10.9rem;
    position: relative;
    overflow: hidden;
    @include bp(lg-max) {
      width: 0.6rem;
      height: 9rem;
    }
    @include bp(sm-max) {
      width: calc(25% - 0.6rem);
      height: 0.5rem;
    }
    &.active {
      &::after {
        height: 100%;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 0;
      @extend .transition;
      background-color: $purple;
    }
  }
}

.close-onboarding-link {
  position: fixed;
  right: 4.8rem;
  bottom: 4.8rem;
  @include bp(lg-max) {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
  }
}

.onboarding-previous-link {
  display: inline-flex;
  line-height: 0;
  position: absolute;
  top: 5.4rem;
  left: calc(50% - 1.1rem);
  font-size: 2.2rem;
  color: $purple;
  @include rotate(180deg);
  @extend .transition;
}

.owner-overview-page,
.member-overview-page,
.member-dashboard {
  padding: 2rem 0;
  .owner-title-block,
  .member-title-block {
    margin-bottom: 4rem;
    @include bp(sm-max) {
      margin-bottom: 3rem;
    }
    .owner-logo {
      text-align: right;
      margin-bottom: 0.9rem;
      .compnay-logo{
        align-items: center;
        display: flex;
        height: 3.2rem;
        justify-content: flex-end;
        img {
          max-height: 100%;
          max-width: 100%;
        }
    }
    }
    h2 {
      margin: 0;
    }
  }
  .payment-waiting-strip {
    margin-bottom: 3.2rem;
    @include bp(lg-max) {
      margin-bottom: 2.5rem;
    }
  }
  .overview-page-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    @include bp(lg-max) {
      flex-direction: column-reverse;
    }
    .overview-left {
      padding: 0 1.2rem;
      width: 75.6%;
      @include bp(lg-max) {
        width: 100%;
      }
      .sidenav-submenu-fixed & {
        @include bp(xlg-max) {
          width: 100% !important;
        }
      }
    }
    .overview-right {
      padding: 0 1.2rem;
      width: 24.4%;
      @include bp(lg-max) {
        width: 100%;
      }
      .sidenav-submenu-fixed & {
        @include bp(xlg-max) {
          width: 100% !important;
          margin-top: 4rem;
        }
        @include bp(lg-max) {
          margin-top: 0;
        }
      }
    }
    .plans-wrapper {
      .plans-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.1rem;
        h5 {
          margin: 0;
        }
      }
      .plan-selection-row {
        .plan-selection-col {
          @include bp(xlg-max) {
            width: 50%;
          }
          @include bp(xs-max) {
            width: 100%;
          }
          .sidenav-submenu-fixed & {
            @include bp(xl-max) {
              width: 50%;
            }
          }
        }
      }
      .enterprise-plan-card {
        .plan-name-wrapper {
          .h2 {
            font-size: 2rem;
            @include line-height(20, 32);
          }
        }
      }
      .selected {
        border-width: 2rem;
      }
    }
    .onboarding-steps {
      @extend .no-bullets;
      @include bp(lg-max) {
        display: flex;
        margin: 0 -1rem 1.6rem;
        flex-wrap: wrap;
      }
      @include bp(sm-max) {
        margin-bottom: 1.5rem !important;
      }
      li {
        margin-bottom: 1.2rem;
        @include bp(lg-max) {
          width: 33.33%;
          padding: 0 1rem;
        }
        @include bp(sm-max) {
          width: 50%;
          margin-bottom: 1.2rem;
        }
        @include bp(xs-max) {
          width: 100%;
          padding: 0 0rem;
        }        
        &.disabled {
          cursor: not-allowed;
        }
        a {
          display: flex;
          padding: 2rem 1.6rem;
          background: rgba($grey6, 0.5);
          border: 2px solid transparent;
          border-radius: 2rem;
          color: $black1;
          cursor: pointer;
          &:hover {
            border: 2px solid $purple2;
          }
          @include bp(smd-max) {
            padding: 1.4rem 1.2rem;
          }
          &:disabled, &.disabled {
            cursor: none;
            &:hover {
              border: none;
            }
          }
          &.is-hover-btn {
            cursor: pointer;            
          }
          &.disabled {
            pointer-events: none;
            .icon {
              background: $lightPurple;
              color: $purple2;
            }
            .sub-title {
              color: $grey5;
            }
            .h6 {
              color: $grey11;
            }
          }
          .step-content {
            width: calc(100% - 4rem);
            padding-left: 1.2rem;
          }
          .icon {
            height: 4rem;
            width: 4rem;
            background: $purple3;
            border-radius: 1.2rem;
            color: $white;
            font-size: 1.8rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          .sub-title {
            display: block;
            font-size: 1rem;
            @include line-height(10, 16);
            letter-spacing: 0.1rem;
            text-transform: uppercase;
          }
          .h6 {
            display: block;
            @include line-height(16, 24);
            @include bp(smd-max) {
              font-size: 1.4rem;
            }
          }
        }
        &:last-child {
          margin: 0;          
        }
      }
    }
  }

  .content-wrapper {
    margin: 0 0 5.8rem 0;
    .inner-content{
      .content-title {
        color: $black1;
        font-size: 1.8rem;
        font-weight: 500;
        @include line-height(18,28);
        margin: 0 0 .4rem 0;
      }
      //.text-description {}
      .top-content-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;        
        .text-description {
          width: calc(100% - 36rem);
        }
        .button-wrap {
          width: 32.8rem;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          .MuiButton-root {
            height: 5.2rem;
            min-height: 5.2rem;
            margin: 0 1.4rem 1.4rem 0;
            font-size: 1.4rem;
            font-weight: 500;
            @include line-height(14,20);
            letter-spacing: 0.014rem;
            &:last-child {
              margin: 0;
            }
            &.has-border {
              border: 1px solid rgba(179, 136, 255, 0.30);
              background: $grey7;
            }
          }
        }
        @include bp(sm-max) {
          flex-flow: column;
          .text-description {
            margin-bottom: 1.2rem;
          }
          .button-wrap {
            justify-content: flex-start;
          }
          .text-description,
          .button-wrap {
            width: 100%;
          }
        }
      }
    }
    .cards-wrap {
      margin: 3.2rem 0 0;
      .card-template {
        border-radius: 0px 0px 0px 20px;
        color: $black1;
        border: 1px solid $grey2;
        margin-bottom: 1.6rem;
        
        &.active, &:hover {
          border-color: $purple3;
        }
        .card-body {
          background: $grey17;
          padding: 1.6rem;
          .top-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon-wrap {
              border-radius: 0.4rem;
              padding: 0.4rem;
              width: 4.8rem;
              height: 4.8rem;
              opacity: 0.9;
              background: $purple1;
              display: flex;
              align-items: center;
              justify-content: center;
              //img {}
            }
            .icon {
              color: $black1;
              cursor: pointer;
              &.move-right {
                transform: rotate(270deg);
              }
            }
          }
          .card-title-container {
            margin: 1.6rem 0 0;
            .card-name {
              color: $black1;
              font-size: 1.6rem;
              font-weight: 400;
              @include line-height(16,24);
              cursor: pointer;
              display: block;              
              &.add-ellipses {
                @extend .truncated-text;
              }
            }
          }
        }
        .card-footer {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid $grey2;
          .content-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-right: 1px solid $grey2;
            padding: 1.6rem;
            width: 50%;
            &:last-child {
              border: 0;
              width: calc(50% - 1px);
            }
            .title {
              color: $grey5;
              font-size: 1rem;
              font-weight: 500;
              @include line-height(10, 16);
              letter-spacing: 0.1rem;
              text-transform: uppercase;
            }
            .count {
              color: $black1;
              font-size: 1.6rem;
              font-weight: 500;
              @include line-height(16, 24);
              text-transform: uppercase;
            }
          }
        }
      }
    }
    &.my-spaces-wrapper {
      margin: 5rem 0 8rem 0;
      @include bp(sm-max) {
        margin: 2rem 0 3rem 0;
      }
    }
    &.my-project-wrapper {
      .inner-content {
        margin: 0 0 2.4rem;
      }
    }
  }
  .no-data-wrapper {
    padding: 5.6rem 2.4rem;
    @include bp(sm-max) {
      margin: 2.6rem 0 2rem 0;
    }
    .thumb {
      margin: 0 auto;
    }
    h4 {
      margin: .8rem 0;
    }
  }
}

.overview-welcome {
  padding: 4rem 6.6rem 4rem 4rem;
  background: $purple1;
  border: 0.1rem solid $grey2;
  border-radius: 0 0 0 2rem;
  color: $white;
  margin-bottom: 3rem;
  position: relative;
  @include bp(xlg-max) {
    padding: 3rem 5rem 3rem 3rem;
  }
  @include bp(sm-max) {
    padding: 5rem 2rem 2rem 2rem;
  }
  .overview-close-btn {
    position: absolute;
    right: 2.7rem;
    top: 2.7rem;
    line-height: 0;
    font-size: 1.8rem;
    z-index: 1;
    cursor: pointer;
    @include bp(xlg-max) {
      right: 1.5rem;
      top: 1.5rem;
    }
  }
  .overview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .left-content {
    width: 43%;
    padding-top: 0.7rem;
    @include bp(smd-max) {
      padding-top: 0;
      width: 100%;
    }
    .boxed-title-block {
      margin-bottom: 1.4rem;
    }
    .MuiButton-root {
      width: 100%;
      max-width: 32.7rem;
    }
    p {
      font-size: 1.6rem;
      @include line-height(16, 24);
      margin-bottom: 2.6rem;
      &:last-child {
        margin: 0;
      }
    }
  }
  .video-wrapper {
    width: 54.4%;
    @include bp(smd-max) {
      width: 100%;
      margin-top: 3rem;
    }
    .video-outer {
      position: relative;
      // overflow: hidden;
      border-radius: 2rem;
      //padding-bottom: 55.3%;
      padding-bottom: 0;
      .video-img-wrap {        
        // @extend .absolute-pos;
        background-color: $purple1;
        opacity: .75;
        z-index: 1;       
        text-align: center;
        img {
          border-radius: 2rem;
        } 
      }      
      .video-banner {
        // @extend .absolute-pos;
        @extend .cover-fit;        
      }
      video {
        border-radius: 2rem;
      }
    }
    .play-btn {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
      line-height: 0;
      display: flex;
      z-index: 2;
      @include bp(lg-max) {
        max-width: 10rem;
      }
      @include bp(sm-max) {
        max-width: 7.5rem;
      }
    }
  }
}

.choose-plan-page {
  padding-bottom: 2.9rem;
  .choose-plan-wrapper {
    padding: 3.7rem 0 0;
  }
}

.compare-plans-features {
  padding: 4.8rem 0;
  h2 {
    margin-bottom: 2.5rem;
  }
}