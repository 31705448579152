// Unauthorized Access || 404
.unauthorised-access-wrap, .page-404 {
  position: relative;
  padding: 0;
  margin: 0;
  .inner-container {
    position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 100%);
    margin-left: -2.3rem;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
    .icon-wrap {
      width: 10rem;
			height: 10rem;
			border-radius: 2rem;
			background: $white;
			box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
      img {
        width: 6rem;
        height: auto;
      }
    }
    .img-wrap {
      .empty-box {
        width: 12rem;
        height: 12rem;
      }
    }
    h3 {
      color: $black;
			font-family: $primaryFont;
			font-size: 3.2rem;
			line-height: 4rem;
			margin: 3.2rem 0 2.4rem;
    }
    p {
      color: $black;
      font-size: 2rem;
      line-height: 3.2rem;
      margin-bottom: 2.4rem;
      margin-top: -0.8rem;
    }
    .MuiButtonBase-root {
      font-size: 1.6rem;
      background: $purple3;
      padding: 1.6rem 2.4rem;
      border-radius: 0.8rem;
      color: $white;
      &:hover {
        opacity: 0.95;
      }
    }
  }
}
.page-404 {
  width: 100%;
  height: 100vh;
  background: $white;
  .inner-container {
    transform: translate(-50%, -50%);
    margin-left: 2.3rem;
    z-index: 2;
  }
  .bg-404 {
    background: url('../images/bg-404.svg') no-repeat 0 0/contain;
    z-index: 1;
    position: absolute;
    top: 20%;
    left: 15%;
    height: 60%;
    width: 70%;
  }
  .logo {
    position: absolute;
    top: 5.4rem;
    left: 11.2rem;
    img {
      width: 12.6rem;
      height: auto;
    }
  }
}