@import "../../assets/scss/colors";
@import "../../assets/scss/fonts";
@import "../../assets/scss/mixins";

Button.demo-button {
  width: 100%;
  display: flex ;
  background-color: $white !important;
  justify-content: space-between ;
  border: 1px solid rgba(179, 136, 255, 0.3);
  transition: all 0.2s ease-in;
  font-size: 1.6rem;
  font-weight: 500;
  color: $black2;
  padding: 0 2.4rem;
  &:hover {
    border-color: $purple;
  }
}