// Common overview tab pages - Space, Project, meet ...
.apps-list {
  padding: 0;
  margin: 0 0 4rem;
  &.project-overview-app-list {
    .section-title {
      font-size: 1.4rem;
      line-height: 2rem;
    }
    .card-template {
      .card-body {
        .tag-container {
          .MuiStack-root {
            .MuiChip-root {
              height: 2rem;
              .MuiChip-label {
                padding: 0;
              }
            }
          }
        }
      }
    }
    .accordion-wrapper {
      &.buildcards {
        .MuiPaper-root {
          .MuiButtonBase-root {
            min-height: inherit;
            
          }
        }
      }
      > .MuiPaper-root {
        border: 0;
        padding: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
      }
      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          padding-top: 1.6rem;
        }
      }
    }
  }
  .section-title {
    font-size: 1.6rem;
    font-weight: 500;
    color: $black1;
    margin: 0 0 1.6rem;
    .clickable {
      cursor: pointer;
    }
    .icon {
      position: relative;
      top: 0.2rem;
      left: 0.2rem;
    }
  }
  .data-container {
    .app-card {
      border-radius:0 0 0 2rem;
      border: 1px solid $grey3;
      transition: all 0.3s ease-in;
      background: $white;
      padding: 2rem 4.5rem 2rem 2rem;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      .data-content {
        display: flex;
        align-items: center;
        width: 100%;
        .thumbnail {
          margin: 0 1.2rem 0 0;
          > span {
            padding: 1.2rem;
            border-radius: 1.2rem;
            background: $dark-red;
            width: 4rem;
            height: 4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 1.6rem;
              color: $white;
              line-height: initial;
            }
          }
        }
        .description {
          width: calc(100% - 5.2rem);
          .sub-title {
            color: $grey9;
            text-transform: uppercase;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            @include line-height(10, 16);
            letter-spacing: 0.1rem;
          }
          .title {
            color: $black1;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;
            @include line-height(16, 24);
            @extend .truncated-text;
          }
        }
      }
      .right-arrow {
        &.rotate-down {
          transform: rotate(-90deg);
          position: absolute;
          right: 2rem;
          top: calc(50% - 0.8rem);
          display: inline-flex;
          .icon {
            display: inline-flex;
          }
        }
        .icon {
          color: $grey11;
        }
      }
      &:hover{
        border-color: $purple2;
        background-color: $lightPurple1;
        box-shadow: 0 0 1.6rem rgba($color: #000000, $alpha: 0.15);
      }
    }
    &.cards-with-graph-wrapper {
      border-radius: 0 0 0 2rem;
      border: 1px solid $grey2;
      background: $white;
      margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
      .card-template {
        border: 0 !important;
        border-right: 1px solid $grey2 !important;
      }
      .graph-container {
        width: 100%;
        height: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 0 1rem 0 0;
        position: relative;
        .no-data-text {          
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
        .no-graph-available {
          background: url(../images/no-graph-bg.svg) no-repeat;
          background-size: 100%;
          background-position: 3px 74%;
          height: 100%;
          margin-right: -1rem;
          .top-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            padding-top: 3.4rem;
            img {
              margin: 0 auto .8rem;
            }
            .info-title {
              margin: 0;
              font-size: 1.4rem;
              font-weight: 500;
              @include line-height(14,20);
              letter-spacing: 0.014rem;
            }
            .info-text {
              margin: 0;
              font-size: 1.2rem;
              font-weight: 400;
              @include line-height(12,16);
              letter-spacing: 0.012rem;
            }
          }
        }
      }
    }
  }
}

// Accordion wrapper
.accordion-wrapper {
  &.buildcards {
    .MuiPaper-root {
      border-radius: 0;
      box-shadow: none;
      background: transparent;
      .MuiButtonBase-root {
        padding: 0;
        margin: 0;
        min-height: 3.6rem;
        .MuiAccordionSummary-content {
          margin: 0;
          .section-title {
            margin: 0;
          }
          .section-title {
            .clickable {
              .icon {
                @extend .transition;
                display: inline-flex;
              }
            }
          }
          &.Mui-expanded {
            margin: inherit;
            min-height: inherit;
            .section-title {
              .clickable {
                .icon {
                  @include rotate(180deg);
                }
              }
            }
          }
        }
      }
      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          .MuiCollapse-wrapperInner {
            .MuiAccordion-region {
              .MuiAccordionDetails-root {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

