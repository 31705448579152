@import '../../../assets/scss/colors';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/extended-classes';
@import '../../../assets/scss/break-points';

.duplicate-buildcard-section {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  align-items: start;
  @include bp(smd-max) {
    flex-wrap: wrap;
  }
  h3 {
    font-size: 1.6rem;
    color: $black1;
    font-weight: 500;
  }
  .dup-buildcard-left {
    position: relative;
    width: 325px;
    z-index: 5;
    @include bp(smd-max) {
      width: 100%;
    }
    .card-template {
      background: $lightPurple1;
      .searching-loader {
        display: flex;
        justify-content: center;
        height: 25.7rem;
        background: $lightPurple1;
      }
    }

    .icon-btn-wrap {
      button {
        padding: 0.4rem 0.8rem;
        border-radius: 1.2rem;
        background-color: transparent;
        min-width: 4rem;
        height: 2.4rem;
        font-size: 1.2rem;
        line-height: 1.3333333333;
        min-height: inherit;
        margin-right: 0;
      }
    }
  }
  .similar-buildcard-block {
    position: relative;
    @include bp(smd-max) {
      width: 100%;
    }
    .similar-card {
      border: 0.1rem solid $grey2;
      width: 20.2rem;
      border-radius: 0.8rem;
      margin-bottom: 2.4rem;
      position: relative;
      @include bp(smd-max) {
        width: 100%;
      }
      .head {
        border-bottom: 0.1rem solid $grey2;
        color: $black1;
        font-size: 1rem;
        font-weight: 400;
        padding: 1.2rem;
        border-radius: 0.8rem 0.8rem 0 0;
        background-color: $grey1;
        z-index: 4;
        position: relative;
        letter-spacing: 0.1rem;
        text-transform: uppercase;

        span {
          letter-spacing: 0;
          text-transform: none;
          display: block !important;
          color: $black1;
          font-size: 1.4rem;
          font-weight: 500;
        }
        &:before {
          width: 0.7rem;
          height: 1.1rem;
          border-radius: 3rem 0rem 0 3rem;
          background-color: $lightPurple;
          content: '';
          top: 1.75rem;
          left: -0.8rem;
          position: absolute;
        }
      }
      .similar-info {
        display: flex;
        padding: 1.2rem 0.8rem 1.2rem 1.2rem;
        align-items: center;
        z-index: 4;
        position: relative;
        .comm-journey {
          padding-left: 0.8rem;
          p {
            font-size: 1.2rem;
            color: $black1;
            letter-spacing: 0.02rem;
            margin: 4px 0;
          }
        }
        .progress-tooltip-popover {
          display: flex;
        }
      }
      .dividerRow {
        width: 100%;
        min-height: 130px;
        order: 2;
        position: absolute;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 22.05vw;
        display: none;
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100vw;
          position: absolute;
          left: 0;
          bottom: 82%;
          border-bottom: 2px solid $lightPurple;
          border-right: 2px solid $lightPurple;
          border-radius: 0px 0px 20px 0px;
          opacity: 0;
        }
        @include bp(smd-max) {
          display: none;
        }
      }
      &.active {
        border-color: $purple3;
        .dividerRow {
          display: block;
          @include bp(smd-max) {
            display: none;
          }
          &::before {
            border-color: $purple3;
            opacity: 1;
          }
        }

        .head {
          background-color: $lightPurple1;
        }
      }

      .active ~ .featureTitle:before {
        border-color: $purple3;
      }
    }
    .searching-loader {
      position: absolute;
      left: -27.4vw;
      z-index: 5;
      width: calc(58.2vw + 6%);
      align-items: center;
      background: $lightPurple1;
      border: 1px dashed $grey2;
      display: flex;
      justify-content: center;
      position: relative;
      height: 29.4rem;
    }
  }
  .dup-buildcard-info {
    background-color: $white;
    width: 36.7rem;
    border: 0.1rem solid $grey2;
    border-radius: 0.8rem;
    position: relative;
    margin-top: 2.5rem;
    z-index: 4;
    @include bp(smd-max) {
      width: 100%;
      margin-top: 0;
    }
    .head {
      border-bottom: 0.1rem solid $grey2;
      padding: 1.6rem 1.2rem;
      .icon {
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: $black1;
        background-color: #c8f8ff;
        margin-right: 0.5rem;
      }
      .supply-chain {
        font-size: 1.2rem;
        color: $purple;
        display: flex;
        align-items: center;
      }
      p {
        font-weight: 500;
        padding-top: 0.6rem;
      }
      .save-credits {
        background-color: $lightPurple;
        padding: 1.2rem;
        border-radius: 0.8rem;
        margin: 0.6rem 0 0;
        .save-credits-row {
          display: flex;
          justify-content: space-between;
          padding: 0.2rem 0;
          span {
            font-size: 1rem;
            font-weight: 500;
            color: $black1;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
          }
        }
        .save-title {
          font-size: 1.4rem;
          font-weight: 500;
          color: $black1;
          letter-spacing: 0.04rem;
          display: block;
          margin-bottom: 0.5rem;
        }
      }
    }
    .save-credits-info {
      padding: 1.6rem 1.2rem 1rem;
      .credits-row {
        display: flex;
        justify-content: space-between;
        padding: 0.3rem 0;
        align-items: center;
        span {
          font-size: 1rem;
          font-weight: 400;
          color: $grey4;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          &:last-child {
            font-size: 1.4rem;
            letter-spacing: 0.04rem;
            text-transform: none;
            color: $black1;
            img {
              width: 1.8rem;
              margin-left: 1rem;
            }
          }
        }
      }
    }
    .lead-col {
      border-top: 0.1rem solid $grey2;
      border-bottom: 0.1rem solid $grey2;
      padding: 1.6rem 1.2rem;
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      position: relative;
      padding-right: 30px;

      .initials,
      .user-avatar {
        @include user-avatar(1.4rem, 500, $black1, $lightPurple, 3.6rem, 3.6rem, 3.6rem);          
        img {
          border-radius: 50%;
        }
      }
      .user-name {
        padding-left: 1.2rem;
        font-size: 1rem;
        color: $black1;
        font-weight: 400;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        span {
          display: block;
          font-size: 1.4rem;
          text-transform: none;
          color: $black1;
          font-weight: 500;
        }
      }
    }
    .view-btn {
      padding: 1.6rem 1.2rem;
      button {
        width: 100%;
        height: 3.6rem;
        min-height: 3.6rem;
      }
    }

    &.active {
      .be-corner-arrow {
        opacity: 1;
      }
    }

    .be-corner-arrow {
      transform: rotate(43deg);
      display: inline-flex;
      position: absolute;
      top: 2.9rem;
      left: -1.6rem;
      color: $purple3;
      opacity: 0;
      font-size: 1.4rem;
    }
  }
  .col {
    width: 33.33%;
    display: flex;
    margin-top: 2rem;
    @include bp(smd-max) {
      width: 100%;
      flex: auto;
      margin-top: 1rem;
    }
  }
}

.duplicate-buildcard-page {
  .tab-contest {
    .MuiTabPanel-root {
      padding: 0;
    }
    .MuiButtonBase-root {
      font-size: 1.6rem;
      min-height: 5.6rem;
      text-transform: capitalize;
      padding-left: 0;
      padding-right: 0;
      min-width: auto;
      margin-right: 4.8rem;
      &:last-child {
        margin-right: 0;
      }
      &.MuiChip-filled {
        min-height: initial;
      }
    }
  }
}

.similar-card {
  position: relative;
  width: 100%;
  height: 132px;
  cursor: pointer;
  &.first-child {
    &:before {
      height: 0.2rem !important;
      width: 23.05vw;
      z-index: 4;
    }
  }
}

.similar-card:before {
  content: '';
  pointer-events: none;
  z-index: 1;
  display: block;
  width: 22.05vw;
  height: calc(100% - -40px);
  position: absolute;
  bottom: 82%;
  border-bottom: 2px solid $lightPurple;
  border-left: 2px solid $lightPurple;
  border-radius: 0 0 0 20px;
  right: 0;
  @include bp(smd-max) {
    display: none;
  }
}

.similar-card.active:before {
  border-bottom: 2px solid #6c00ea;
  border-left: 2px solid #6c00ea;
  z-index: 2;
  height: 100vh;
}

.similar-card.active .head:before {
  background: #6c00ea !important;
}

.similar-buildcard-block.active:after {
  content: '';
  pointer-events: none;
  z-index: 4;
  display: block;
  width: 19.1vw;
  height: 3rem;
  position: absolute;
  top: 6.1rem;
  border-top: 2px solid #6c00ea;
  border-right: 2px solid #6c00ea;
  border-radius: 0px 20px 0px 0;
  right: 22vw;
  @include bp(xlg-max) {
    right: 21.95vw;
  }
  @include bp(smd-max) {
    display: none;
  }
}

.similar-buildcard-block.active::before {
  content: '';
  pointer-events: none;
  z-index: 3;
  display: block;
  width: 2px;
  height: 10.7rem;
  position: absolute;
  top: -3.2rem;
  border-top: 0;
  border-right: 3px solid $grey1;
  border-radius: 0px 20px 0px 0;
  right: 22vw;
  @include bp(xlg-max) {
    right: 21.95vw;
  }
  @include bp(smd-max) {
    display: none;
  }
}

.buildcard-sub-tab {
  border: 0 none;
  .MuiTabs-root {
    .MuiTabs-fixed {
      .MuiTabs-flexContainer {
        width: fit-content;
        background: $grey13;
        border-radius: 0.8rem;
        .MuiTab-root {
          padding: 6px 12px;
          color: $black1;
          text-transform: none;
          letter-spacing: 0.014rem;
          @include line-height(14, 20);
          font-weight: 400;
          min-height: initial;
          font-size: 1.4rem;
          margin-right: 0;
          &.Mui-selected {
            background: $lightPurple;
            color: $purple1;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            border-radius: 0.8rem;
            letter-spacing: 0.014rem;
          }
        }
      }
      .MuiTabs-indicator {
        background: none;
        height: 0;
      }
    }
  }
}

.attributes-section {
  display: flex;
  width: 100%;
  .MuiPaper-rounded {
    box-shadow: none;
    border: 0.1rem solid $grey2;
    border-radius: 0.8rem;
  }
  .MuiPaper-root {
    table {
      th {
        border-bottom-color: $grey2;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 500;
        color: $black1;
        height: 4.4rem;
        padding: 0 1.2rem;
        background-color: $grey1;
        text-transform: none;
        letter-spacing: 0;
        width: auto;
        &:first-child {
          border-right: 0.1rem solid $grey2;
          width: 25%;
          white-space: nowrap;
        }
      }
      td {
        border-bottom-color: $grey2;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black1;
        height: 4.4rem;
        padding: 1.2rem !important;
        vertical-align: middle;
        &:first-child {
          border-right: 0.1rem solid $grey2;
        }
        .platform-col {
          display: flex;
          img {
            max-width: 1.8rem;
            margin-right: 0.8rem;
          }
        }
        .progress {
          display: flex;
          align-items: flex-start;
          align-items: center;
          img {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .collapse-table {
      .blank {
        margin: 0;
        padding: 0 !important;
        border: 0;
        height: auto;
        td {
          width: 25%;
          border: 0;
          position: relative;
          &:first-child {
            border-right: 0.1rem solid $grey2;
          }
          &:hover {
            .note-btn {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      tr {
        &:last-child {
          td {
            // border-bottom: 0.1rem solid $grey2;
          }
        }
      }
    }
  }

  .supply-chain {
    font-size: 1.2rem;
    color: $purple;
    display: flex;
    align-items: center;
    .icon {
      width: 1.8rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      color: $black1;
      background-color: #c8f8ff;
      margin-right: 0.5rem;
    }
    p {
      font-weight: 500;
      padding-top: 0.6rem;
    }
  }

  .MuiChip-root {
    height: 2.4rem;
    background-color: $lightPurple;
    display: inline-flex;
    align-items: center;
    margin: 0.4rem 0.5rem 0.4rem 0;
    span {
      font-size: 1.2rem;
      font-weight: 500;
      color: $black1;
    }
  }
  .lead-name {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: $black1;
    letter-spacing: 0.03rem;
    .initials {
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 2.4rem;
      min-width: 2.4rem;
      background: $purple2;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: 500;
      margin-right: 1rem;
      color: $white;
      font-style: normal;
    }
  }
  .attributes-table {
    width: calc(100% + -258px);
    @include bp(sm-max) {
      width: calc(100% + -150px);
    }
    &.left {
      width: 24rem;
      margin-right: 2rem;
      @include bp(sm-max) {
        width: 15rem;
      }
      table {
        min-width: initial;
        td {
          border-right: 0 !important;
        }
        tr {
          &:last-child,
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10) {
            height: auto !important;
          }
        }
      }
      .height {
        height: 9.1rem;
        display: block;
      }
    }
    .status-tags {
      height: 2rem;
      padding: 0 0.8rem;
      font-size: 1rem;
      letter-spacing: 0.1rem;
      color: $white;
      border-radius: 3rem;
      background-color: $grey4;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
.buildcard-sub-tab {
  margin-top: 1.5rem;
  .MuiBox-root {
    border: 0;
  }
  .be-close {
    color: $red3;
    font-size: 1rem;
    width: 1.4rem;
    display: inline-block;
    &::before {
      font-weight: 700 !important;
    }
  }

  .be-tick {
    color: #00c853;
    font-size: 1.4rem;
    position: relative;
    button {
      right: -9rem !important;
      top: -0.2rem !important;
    }
  }

  .note {
    border: 0.1rem solid $purple1;
    border-radius: 3rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .hover-feature-note {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    color: $grey4;
    position: absolute;
    right: 2.5rem;
    display: flex;
    align-items: center;
    margin-top: -4rem;
    em {
      display: inline-flex;
      margin-right: 0.5rem;
    }
    @include bp(sm-max) {
      margin-top: 0;
      left: 2.4rem;
    }
  }

  .Journey-table {
    @include bp(sm-max) {
      margin-top: 3rem;
    }
    .MuiIconButton-sizeSmall {
      width: 1.6rem;
      height: 1.6rem;
      min-height: 1.6rem;
    }
    .note-btn {
      border: 0.1rem solid $purple4;
      border-radius: 3rem;
      font-size: 1rem;
      color: $purple4;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      height: 2rem;
      padding: 0 0.8rem;
      background: transparent;
      min-height: initial;
      display: flex;
      line-height: 2rem;
      align-items: center;
      position: absolute;
      right: 1.5rem;
      top: 1.4rem;
      opacity: 0;
      visibility: hidden;
    }
    .collapsible-table {
      min-width: 1377px;
      @include bp(sm-max) {
        width: 200%;
      }
    }
    .searching-loader {
      height: 50vh;
    }
    .MuiPaper-root {
      overflow: auto;
      table {
        th {
          width: auto;
          &:first-child {
            width: 22%;
            position: sticky;
            left: 0;
            background-color: $white;
            z-index: 1;
          }
        }
        td {
          width: auto;
          &:first-child {
            width: 22%;
            position: sticky;
            left: 0;
            background-color: $white;
            z-index: 1;
          }
        }
      }
      .collapse-table {
        .blank {
          td {
            width: auto;
            &:first-child {
              width: 22%;
              position: sticky;
              left: 0;
              background-color: $white;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.second {
  .col {
    width: 20%;
  }
}

.note-info {
  font-size: 1.4rem;
  line-height: 2rem;
  color: $dark-grey;
  padding: 0.6rem 1rem 0.6rem 0;
  display: block;
  overflow: auto;
  margin-right: -1rem;
  @include custom-scroll;
  max-height: 17.5rem;
  strong {
    display: block;
    color: #333;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}

.similar-buildcard-block.active .line {
  content: '';
  pointer-events: none;
  z-index: 4;
  display: block;
  width: 19.1vw;
  height: 3rem;
  position: absolute;
  top: 6.1rem;
  border-top: 2px solid #6c00ea;
  border-left: 2px solid #6c00ea;
  border-radius: 2rem 0 0 0;
  left: 22vw;
  @include bp(xxl-max) {
    width: 11.4vw;
  }

  &:before {
    content: '';
    pointer-events: none;
    z-index: 3;
    display: block;
    width: 2px;
    height: 10.7rem;
    position: absolute;
    top: -9.3rem;
    border-top: 0;
    border-right: 13px solid #fafbfc;
    border-radius: 5rem;
    right: 19vw;
    @include bp(xxl-max) {
      right: 11.3vw;
    }
  }
  @include bp(smd-max) {
    display: none;
  }
}

.duplicate-buildcard-section {
  &.active {
    .similar-buildcard-block {
      &.active {
        &:after {
          border-right: 0 solid #6c00ea;
          border-radius: 0;
        }
        &:before {
          top: -4.5rem;
        }
        .line {
          border-radius: 0;
          border-left: 0;
          &:before {
            top: -10.9rem;
            border-radius: 0;
          }
        }
      }
    }
    .similar-card {
      &.active {
        .dividerRow {
          &::before {
            border-radius: 0;
          }
        }
      }
    }
  }
}

.sidenav-visible {
  .duplicate-buildcard-section {
    .similar-buildcard-block {
      .dividerRow {
        @include bp(xl-max) {
          width: 18.05vw;
        }
      }
      &.active {
        .line {
          @include bp(xl-max) {
            left: 18vw;
          }
        }
        .similar-card {
          &:before {
            @include bp(xl-max) {
              width: 18.05vw;
            }
          }
        }
        &::after {
          @include bp(xl-max) {
            right: 18vw;
          }
        }
        &::before {
          @include bp(xl-max) {
            right: 18vw;
          }
        }
      }
    }
  }
}

.circle-outer {
  border: 0.1rem solid $purple3;
  border-radius: 100%;
  display: inline-flex;
  margin-right: 0.5rem;

  .circle {
    position: relative;
    width: 1.2rem;
    min-height: 1.2rem;
    transform: rotate(4deg);
    backface-visibility: hidden;
    transition:
      width 2s,
      height 4s;
    border-radius: 100%;
    margin: 0.1rem;
  }
}

.right-buildcard {
  .loading-container {
    align-items: center;
    background: $lightPurple1;
    border: 0.1rem dashed $grey2;
    display: flex;
    justify-content: center;
    position: relative;
    height: 33.4rem;
    width: 36.7rem;
    z-index: 9;
  }
}

.collapsible-row {
  border-top: 0.1rem solid $grey2;
}
