// Right summry - Tag result
.tag-result-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  &::before {
    content: '';
    z-index: 100;
    background: rgba($grey7, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.tag-result {
  position: relative;
  right: 0;
  top: 0;
  width: 57.9rem;
  //height: 100%;
  height: 100vh;
  border-left: 1px solid $grey2;
  padding: 2.4rem;
  font-size: 1.4rem;
  color: $black1;
  background: $white;
  @extend .transition;
  @include line-height(14, 20);
  box-shadow:
    0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.05),
    0 2.8rem 4rem 0 rgba(0, 0, 0, 0.08);
  z-index: 101;
  &.flexbox {
    display: flex;
    flex-direction: column;
    @include bp(sm-max) {
      width: auto;
    }
  }

  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.8rem 0;

    .MuiButtonBase-root {
      &.closeButton {
        width: 2.4rem;
        height: 2.4rem;
        min-width: 2.4rem;
        font-size: 2rem;
        font-weight: 300;
        background: transparent;
        .MuiTouchRipple-root {
          display: none;
        }
        &:hover,
        &:focus,
        &:visited,
        &:active,
        &.Mui-focusVisible {
          background: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }
      }
    }

    .MuiPaper-root {
      .MuiList-root {
        margin-top: 0.4rem;
      }
    }
  }

  .section-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 1.2rem 0;
    color: $black;
    letter-spacing: 0.1rem;
  }

  .content-row {
    padding: 1.6rem 0 !important;
    position: relative;
    z-index: 101;

    &.title-row {
      border-top: 0 !important;
      padding-top: 0 !important;
    }

    // .corner-link {
    //   position: absolute;
    //   right: 0;
    //   top: 2rem;
    //   line-height: 0;
    //   font-size: 1.6rem;
    //   color: $grey11;
    //   cursor: pointer;
    // }

    &:first-child {
      border-top: 0;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    @include bp(smd-max) {
      width: 100%;
      padding: 1.6rem 0;
    }

    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4285714286;
      letter-spacing: 0.01em;
      font-weight: 500;
      margin-right: 2.4rem;
      cursor: pointer;

      .initials,
      .user-avatar {
        @include user-avatar(
          1.2rem,
          500,
          $black1,
          $lightPurple,
          3.6rem,
          3.6rem,
          3.6rem
        );
        background: transparent !important;
        img {
          border-radius: 0 !important;
        }
      }

      .user-name {
        padding-left: 1.2rem;
      }
    }

    .user-detail {
      padding-left: 1.2rem;
      letter-spacing: 0.014rem;
      color: $black1;
      font-weight: normal;

      .user-name {
        padding-left: 0;
        color: $purple1;
      }

      .description-text {
        .time {
          color: $grey5;
        }
      }
    }

    .MuiStack-root {
      flex-wrap: wrap;
      margin-bottom: -8px;

      &.purple-chips {
        .MuiChip-root {
          background-color: $lightPurple;
          color: $black;
          font-weight: 500;
        }
      }
      .MuiChip-root {
        margin: 0 0.8rem 0.8rem 0;
        max-width: fit-content;
      }
    }

    // .recent-activity {
    //   max-height: 24rem;
    //   overflow: auto;
    //   @include custom-scroll;

    //   &.no-scroll {
    //     max-height: inherit;
    //     overflow: inherit;
    //   }

    //   .user-link {
    //     padding: 1.5rem 0;
    //   }

    //   @include bp(smd-max) {
    //     display: flex;
    //     flex-flow: column;
    //   }

    //   .time {
    //     color: $grey5;
    //     position: relative;
    //     padding-left: 10px;
    //     margin-left: 8px;

    //     &::before {
    //       content: "";
    //       position: absolute;
    //       left: 0;
    //       top: 50%;
    //       transform: translateY(-50%);
    //       height: 3px;
    //       width: 3px;
    //       display: block;
    //       background: $grey5;
    //       border-radius: 50%;
    //     }
    //   }
    // }

    .tags {
      display: flex;
      align-items: baseline;
      max-width: 100%;
      &.is-scrollable {
        max-height: 100px;
        overflow: hidden auto;
        @include custom-scroll;
      }

      .search-tag-container {
        min-width: 12rem;
        padding-right: 0.8rem;
        &.form-group {
          margin: 0;
          top: -0.2rem;
          width: 13rem;
          display: inline-flex;
          position: relative;
          .MuiInputBase-root {
            border: 0;
            padding-left: 0;
            height: 3rem;
            .MuiInputAdornment-root {
              input {
                color: $grey7;
                height: 2rem;
              }
            }
          }
        }
        ::placeholder {
          color: $grey5 !important;
        }
      }
    }

    .MuiStack-root {
      margin: 0;
      .MuiButtonBase-root {
        @extend .transition;
        position: relative;

        .MuiChip-label {
          padding: 0.4rem 0.8rem;
          max-width: 16rem;
          min-width: 5rem;
          text-align: center;
          @extend .truncated-text;
          @extend .transition;
        }

        .MuiChip-deleteIcon {
          opacity: 0;
          font-size: 0.8rem;
          color: $black1;
          position: absolute;
          right: 0.8rem;
          top: 0.4rem;
          padding: 0.4rem;
          margin: 0;
          line-height: 1;
          display: inline-block;
          background-color: $lightPurple;
          transition: all ease-in 0.1s;
        }

        &:hover {
          background: $lightPurple;
          cursor: pointer;
          .MuiChip-deleteIcon {
            opacity: 1;
          }
        }
      }
    }

    .result-count-wrap {
      padding: 1.4rem 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.4rem;
        @include line-height(14, 20);
        letter-spacing: 0.14px;
        font-weight: 500;
        padding: 1.2rem 1rem;
        color: $trueGrey;
        > span {
          padding: 0;
        }
      }

      .MuiInputBase-root {
        width: 22rem;
        height: 4.4rem;
        z-index: 101;
        legend {
          display: none;
        }
        .MuiSvgIcon-root {
          display: none !important;
        }
        fieldset {
          top: 0;
        }
        &::after {
          content: '\4e';
          font-family: $enterpriseFont;
          font-size: 1.4rem;
          line-height: 0;
          position: absolute;
          right: 1.6rem;
          top: 50%;
          color: $black1;
          @extend .transition;
        }
        &.Mui-focused {
          &::after {
            transform: rotate(180deg);
          }
        }
        .MuiSelect-select {
          img,
          .icon {
            display: none;
          }
        }
      }

      .MuiAutocomplete-popper {
        margin-top: 0.8rem;
        .MuiPaper-root {
          ul.MuiAutocomplete-listbox {
            > span {
              .showing-result-dropbox {
                display: flex;
                align-items: center;
                width: 100%;

                .left-side {
                  .thumb {
                    margin-right: 0.8rem;

                    em,
                    img {
                      width: 1.6rem;
                      height: 1.6rem;
                    }
                  }
                }

                .right-side {
                  font-size: 1.4rem;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    //Tags
    .search-tag-dropdown-menu {
      .MuiPaper-root {
        min-width: 17.6rem;
        @include custom-scroll;
        z-index: 101;

        .MuiList-root {
          .MuiButtonBase-root {
            &:hover {
              background: inherit;
              cursor: pointer;
            }

            .MuiStack-root {
              .MuiChip-root {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .content-section {
    border-top: 1px solid $grey2;
    display: block;
    width: calc(100% + 4.8rem);
    margin: 0 -2.4rem;
    padding: 0 2.4rem;
    z-index: 101;
    position: relative;

    &.scrollable {
      flex: 1;
      overflow: auto;
      @include custom-scroll;
    }
    .content-row {
      border: 0 !important;
      display: flex;
      align-items: center;
      .right-content {
        .category-name {
          font-size: 1.2rem;
          font-weight: 400;
          color: $grey4;
          max-width: 35rem;
          @extend .truncated-text;
          @include line-height(12, 16);
        }
        .title {
          font-size: 1.4rem;
          font-weight: 500;
          color: $black1;
          text-transform: capitalize;
          max-width: 35rem;
          @extend .truncated-text;
          @include line-height(14, 20);
          letter-spacing: 0.14px;
        }
        .chips {
          .MuiStack-root {
            flex-direction: row;
            .MuiChip-label {
              font-weight: 500;
              max-width: 30rem;
              @extend .truncated-text;
            }
          }
        }
      }
    }
  }
}

.header-search-tag-dropdown-menu {
  .MuiMenu-paper {
    max-height: 16rem;
    min-width: 20rem !important;
    overflow-x: hidden;
    overflow-y: auto;
    @include custom-scroll;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;

    .MuiList-root {
      .dropdown-wrapper {
        .MuiStack-root {
          flex-direction: column;
          align-items: baseline;
          flex-wrap: nowrap;
          padding: 0;

          .list-item-row {
            padding: 0.8rem 1.6rem;
            display: block;
            width: 100%;
            margin: 0;
            border-bottom: 1px solid $grey6;
          }
          .MuiChip-root {
            margin-bottom: 0.8rem;
            &:last-child {
              margin: 0;
            }
          }
          .MuiButtonBase-root {
            background: $lightPurple;
            padding: 0;
            height: 2.4rem;
            border: 1px solid transparent;
            transition: all ease-in 0.2s;
            .MuiChip-label {
              @extend .truncated-text;
              max-width: 16rem;
              min-width: 5rem;
              text-align: center;
              font-size: 1.2rem;
              font-weight: 500;
              padding: 0.4rem 0.8rem;
              @include line-height(12, 16);
              letter-spacing: 0.012rem;
            }
            &:hover {
              background: $lightPurple;
              border: 1px solid $purple2;
            }
          }
        }
      }
    }
  }
}
.tags-menu {
  .MuiMenu-paper {
    min-width: 16rem !important;
    max-width: 30rem;
    overflow: hidden;
    overflow-y: auto;
    @include custom-scroll;
    box-shadow: 0px 4px 16px 0px #00000026 !important;

    .MuiList-root {
      .MuiButtonBase-root {
        width: 100%;
        border-bottom: 1px solid $grey6;
        display: block;
        margin: 0;
        padding: 0.8rem 1.6rem;
        .list-item {
          border-radius: 1.2rem;
          background: $lightPurple;
          padding: 0.4rem 0.8rem;
          margin: 0;
          font-size: 1.2rem;
          font-weight: 500;
          width: max-content;
          @include line-height(12, 16);
          letter-spacing: 0.012rem;
          max-width: 18rem;
          display: block;
          @extend .truncated-text;
          transition: all ease-in 0.2s;
          border: 1px solid transparent;
        }
        img {
          margin: 0 0.8rem 0 0;
          width: 1.6rem;
          height: 1.6rem;
        }
        &:hover {
          background: transparent;
          cursor: default;
        }
      }
    }
  }
}
