.transition {
  @include transition(all 0.3s ease-in);
}

.bg-props {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.absolute-pos {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.cover-fit {
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.truncated-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.be-radius {
  border-radius: 0 0 0 2rem;
}

.last-row-tooltips {
  &:nth-last-child(2):not(:first-child):not(:nth-child(2)),
  &:last-child:not(:first-child):not(:nth-child(2)) {
    .tooltip-wrapper {
      .tooltip-icon {
        &:hover {
          .tooltip-content {
            @include translate(-50%, 0rem);
            opacity: 1;
            visibility: visible;
            &.right-align {
              @include translate(0, 0rem);
            }
          }
        }
      }
      .tooltip-content {
        top: auto;
        bottom: 100%;
        padding-top: 0;
        padding-bottom: 0.8rem;
        @include translate(-50%, -1rem);
        &.right-align {
          @include translate(0, -1rem);
        }
        .content-inner {
          box-shadow: 0 -0.6rem 1.5rem -0.3rem rgba($black, 0.15);
        }
        &::before {
          top: auto;
          bottom: 0.2rem;
        }
      }
    }
  }
}
.three-dots-btn {
  font-size: 2rem;
  margin: 0 -0.5rem 0 0.6rem;
  .be-vertical-dots {
    display: flex;
    align-items: center;
  }
}
